import axios from 'axios';
import history from '../../history';

export const FETCH_TOLLBALANCES = 'FETCH_TOLLBALANCES';
export const FETCH_TOLLBALANCE = 'FETCH_TOLLBALANCE';
export const FETCH_TOLLBALANCE_LIST = 'FETCH_TOLLBALANCE_LIST';
export const CREATE_TOLLBALANCE = 'CREATE_TOLLBALANCE';
export const EDIT_TOLLBALANCE = 'EDIT_TOLLBALANCE';
export const TOGGLE_TOLLBALANCE = 'TOGGLE_TOLLBALANCE';
export const DELETE_TOLLBALANCE = 'DELETE_TOLLBALANCE';
export const DONE_TOLLBALANCE = 'DONE_TOLLBALANCE';
export const PENDING_TOLLBALANCE = 'PENDING_TOLLBALANCE';
export const ERROR_TOLLBALANCE = 'ERROR_TOLLBALANCE';
export const FETCH_TOLLBALANCE_TIERS = 'FETCH_TOLLBALANCE_TIERS';
export const FETCH_TOLLBALANCE_STOCK_TYPES = 'FETCH_TOLLBALANCE_STOCK_TYPES';

const initialFilter = {
    name: null,
    partnerType: 1,
    hasActiveCampaigns: true,
    isPermanent: null,
    beginDate: null,
    endDate: null,
    dateTime: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null
    },
    isEnabled: null,
    showFinished: false,
};


export const fetchTollBalanceCampaigns = (page, pageSize, filters = initialFilter) => async dispatch => {
    dispatch({type: PENDING_TOLLBALANCE});
    try {
        const params = {
            "page": page,
            "pageSize": pageSize,
            "name": filters.name,
            "hasActiveCampaigns": filters.hasActiveCampaigns,
            "isEnabled": filters.isEnabled,
            "partnerType": 1
        };
        const response = await axios.get('/Partners/GetCampaignPartners', {
            headers: { language: localStorage.getItem('i18nextLng')},
            params: params
        });
        if (response) {
            dispatch({type: FETCH_TOLLBALANCES, payload: response.data});
            dispatch({type: DONE_TOLLBALANCE, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};

export const fetchTollBalanceCampaignList = (partnerId, page, pageSize, filters = initialFilter) => async dispatch => {
    dispatch({type: PENDING_TOLLBALANCE});
    try {
        const params = {
            "partnerId": partnerId,
            "page": page,
            "pageSize": pageSize,
            "showFinished": filters.showFinished,
            "name": filters.name,
            "isPermanent": filters.isPermanent,
            "beginDate": filters.beginDate,
            "endDate": filters.endDate,
            "monday": filters.dateTime && filters.dateTime.monday,
            "tuesday": filters.dateTime && filters.dateTime.tuesday,
            "wednesday": filters.dateTime && filters.dateTime.wednesday,
            "thursday": filters.dateTime && filters.dateTime.thursday,
            "friday": filters.dateTime && filters.dateTime.friday,
            "saturday": filters.dateTime && filters.dateTime.saturday,
            "sunday": filters.dateTime && filters.dateTime.sunday,
            "isEnabled": filters.isEnabled,
            "partnerType": 1
        };
        const response = await axios.get('/Campaigns/GetCampaigns', {
            headers: { language: localStorage.getItem('i18nextLng')},
            params: params
        });
        if (response) {
            dispatch({type: FETCH_TOLLBALANCE_LIST, payload: response.data});
            dispatch({type: DONE_TOLLBALANCE, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};


export const fetchTollBalanceCampaign = id => async dispatch => {
    dispatch({type: PENDING_TOLLBALANCE});
    try {
        const response = await axios.get(`/Campaigns/GetById/${id}`);
        if (response) {
            dispatch({type: FETCH_TOLLBALANCE, payload: response.data});
            dispatch({type: DONE_TOLLBALANCE});
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};


export const createTollBalanceCampaign = (formValues, type) => async dispatch => {
    try {

        debugger;
        const response = await axios.post('/Campaigns', formValues);
        console.log(response);

        if (response) {
            dispatch({type: CREATE_TOLLBALANCE, payload: 'operationSuccess'});
            let pathname = history.location.pathname;
            history.replace(pathname.split(type)[0]);
        }
    } catch (e) {
        dispatch({type: CREATE_TOLLBALANCE, payload: e.message});
    }
};

export const editTollBalanceCampaign = formValues => async dispatch => {
    try {
        const response = await axios.put('/Campaigns', formValues);
        if (response) {
            dispatch({type: EDIT_TOLLBALANCE, payload: 'operationSuccess'});
            let pathname = history.location.pathname;
            history.replace(pathname.split('/edit')[0]);
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};

export const toggleTollBalanceCampaign = formValues => async dispatch => {
    dispatch({type: PENDING_TOLLBALANCE});
    try {
        const response = await axios.patch('/Campaigns', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_TOLLBALANCE, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};

export const deleteTollBalanceCampaign = (id, type, partnerName, partnerId, currentPage, pageSize, filters = initialFilter) => async dispatch => {
    dispatch({type: PENDING_TOLLBALANCE});
    try {
        const response = await axios.delete(`/Campaigns/${id}`);
        if (response) {
            dispatch({type: DELETE_TOLLBALANCE, payload: 'operationSuccess'});
            if (type === 'list') {
                dispatch(fetchTollBalanceCampaignList(partnerId, currentPage, pageSize, filters.name, filters.hasActiveCampaigns, filters.isPermanent, filters.beginDate, filters.endDate, filters.isEnabled, filters.showFinished));
            } else {
                let pathname = history.location.pathname;
                history.replace(pathname.split('/edit')[0]);
            }
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};

export const fetchTiers = () => async dispatch => {
    dispatch({type: PENDING_TOLLBALANCE});
    try {
        const response = await axios.get('/Campaigns/GetTiers', {
            headers: { language: localStorage.getItem('i18nextLng')},
        });
        if (response) {
            dispatch({type: FETCH_TOLLBALANCE_TIERS, payload: response.data});
            dispatch({type: DONE_TOLLBALANCE, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};

export const fetchStockTypes = () => async dispatch => {
    dispatch({type: PENDING_TOLLBALANCE});
    try {
        const response = await axios.get('/Campaigns/GetStockUnitTypes', {
            headers: { language: localStorage.getItem('i18nextLng')},
        });
        if (response) {
            dispatch({type: FETCH_TOLLBALANCE_STOCK_TYPES, payload: response.data});
            dispatch({type: DONE_TOLLBALANCE, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_TOLLBALANCE, payload: e.message});
    }
};