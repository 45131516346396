import React from 'react';
import {Route, Switch} from 'react-router-dom';
import BannerList from '../../../BannerContent/BannerList';
import BannerCreate from '../../../BannerContent/BannerCreate';
import BannerEdit from '../../../BannerContent/BannerEdit';

export default () => (
    <Switch>
        <Route path="/banners" exact component={BannerList}/>
        <Route path="/banners/create" component={BannerCreate}/>
        <Route path="/banners/edit/:id" component={BannerEdit}/>
    </Switch>
);