import React from 'react';
import WysiwygElement from "./WysiwygElement";
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const TextEditor = props => {
    const {label, input, meta: {touched, error, submitFailed}, disabled, id} = props;
    const {t} = useTranslation('validation');
    
    return (
        <div className="form__form-group">
            <label className="form__form-group-label" htmlFor={id}>{label}</label>
            <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                    <WysiwygElement
                        id={id}
                        disabled={disabled}
                        {...input}
                    />
                    {touched && error && submitFailed && <span className="form__form-group-error">{t(`validate->${error}`)}</span>}
                </div>
            </div>
        </div>
    );
};

TextEditor.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        submitFailed: PropTypes.bool
    }),
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

TextEditor.defaultProps = {
    meta: null,
    options: [],
    placeholder: '',
    label: '',
    id: ''
};

export default TextEditor; 