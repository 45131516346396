import React, {PureComponent} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

class SelectField extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool
            ]),
            label: PropTypes.string,
        })),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                value: PropTypes.oneOfType/[
                    PropTypes.string,
                    PropTypes.number
                ],
                label: PropTypes.string,
            }),
            PropTypes.arrayOf(PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            })),
        ]).isRequired,
        isDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        isSearchable: PropTypes.bool,
        isMulti: PropTypes.bool
    };

    static defaultProps = {
        placeholder: '',
        isSearchable: false
    };

    handleChange = (selectedOption) => {
        const {onChange} = this.props;
        onChange(selectedOption);
    };

    render() {
        const {t, value, name, placeholder, options, defaultValue, isDisabled, isLoading, isSearchable, isMulti} = this.props;

        return (
            <Select
                name={name}
                value={value || defaultValue}
                onChange={this.handleChange}
                options={options}
                defaultValue={defaultValue}
                isClearable={false}
                className="react-select"
                placeholder={placeholder}
                classNamePrefix="react-select"
                noOptionsMessage={() => t(`common:labelSelectNoOptions`)}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isSearchable={isSearchable}
                isMulti={isMulti}
            />
        );
    }
}

const SelectElement = (props) => {
    const {input, meta: {touched, error, submitFailed}, options, defaultValue, placeholder, className, label, disabled, isLoading, isSearchable, isMulti} = props;
    const {t} = useTranslation('validation');
    
    return (
        <div className="form__form-group">
            <span className="form__form-group-label">{label}</span>
            <div className="form__form-group-field">
                <div className={`form__form-group-input-wrap ${className}`}>
                    <SelectField
                        {...input}
                        options={options}
                        placeholder={placeholder}
                        isDisabled={disabled}
                        isLoading={isLoading}
                        defaultValue={defaultValue}
                        isSearchable={isSearchable}
                        isMulti={isMulti}
                        t={t}
                    />
                    {touched && error && submitFailed &&
                    <span className="form__form-group-error">{t(`validate->${error}`)}</span>}
                </div>
            </div>
        </div>
    );
};

SelectElement.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        label: PropTypes.string,
    })),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool
};

export default SelectElement;
