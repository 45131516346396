import React from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

class Hidden extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        hiddenValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
    };

    componentDidMount() {
        const {onChange, hiddenValue} = this.props;
        onChange(hiddenValue);
    }

    render() {
        const {value, onChange, name} = this.props;

        return (
            <>
                <input
                    type="hidden"
                    name={name}
                    onChange={onChange}
                    value={value}
                />

            </>
        )
    }
}

const HiddenElement = (props) => {
    const {input, hiddenValue, meta: {touched, error, submitFailed}} = props;
    const {t} = useTranslation('validation');
    return (
        <>
            <Hidden
                {...input}
                hiddenValue={hiddenValue}
            />
            {touched && error && submitFailed &&
            <span className="form__form-group-error mb-2">{t(`validate->${error}`)}</span>}
        </>
    );
};

HiddenElement.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number
        ]),
    }).isRequired,
    hiddenValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        submitFailed: PropTypes.bool
    }),
};

export default HiddenElement; 