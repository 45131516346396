import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, getFormSyncErrors} from 'redux-form';
import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import {required, maxLength256} from '../../shared/validations';
import classnames from 'classnames';
import {withTranslation} from "react-i18next";
import InputElement from '../../shared/components/form/Input';
import CheckBoxElement from '../../shared/components/form/CheckBox';
import HiddenElement from '../../shared/components/form/Hidden';
import ModalConfirmation from '../../shared/components/common/ModalConfirmation';
import FileInput from "../../shared/components/form/FileInput";
import ImagePreview from "../../shared/components/form/ImagePreview";
import { IMAGE_TYPE_FORMAT, IMAGE_MAX_SIZE } from "./variables";

class BannerForm extends React.Component {
    state = {
        activeTab: 0,
        languages: this.props.languages,
        bannerImg: null,
    };

    onSubmit = (formValues) => {
        const { languages, type, initialValues } = this.props;
        const { bannerImg } = this.state;
        let formToPost = {
            translations: [{
                title: null,
                link: null,
                image: {
                    imageData: null,
                },
            }],
            isEnabled: null,
        };

        formToPost = {
            ...formToPost,
            isEnabled: formValues.isEnabled,
        };
       
        languages.map((item, i) => {
            formToPost.translations[i].title = formValues.translations[i].title;
            formToPost.translations[i].link = formValues.translations[i].link;
            formToPost.translations[i].language = formValues.translations[i].language;
            if (formValues.translations[i].image) {
                formToPost.translations[i].image = formValues.translations[i].image;
            }
        });

        if (bannerImg) {
            formToPost.translations[0].image.imageData = bannerImg;
            type === 'edit' && (formToPost.translations[0].image.id = initialValues.translations[0].image.id);
        } else {
            formToPost.translations[0].image = initialValues.translations[0].image;
        }

        if (type === "edit") {
            formToPost.id = formValues.id;
            languages.map((item, i) => {
                formToPost.translations[i].id = formValues.translations[i].id;
            });
        }
        this.props.onSubmit(formToPost);
    };

    handleModalDelete = id => {
        this.props.onDelete(id);
    };

    handleImageUpload(e) {
        if (e) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            reader.onloadend = (e) => this.setState({ bannerImg: e.target.result.split(",")[1] });
        }
    }

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;

        if (formSyncErrors.translations) {
            formSyncErrors.translations.some((item, i) => {
                if (item) {
                    this.state.activeTab !== i && this.setState({activeTab: i});
                    return true;
                }
            })
        }
    }

    renderTabHeader() {
        const {activeTab, languages} = this.state;

        return (
            languages.map((item, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({active: activeTab === index})}
                            onClick={() => {
                                this.toggleTab(index);
                            }}
                        >
                            {item.label}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderTabContent() {
        const {languages, bannerImg} = this.state;
        const { t, type, bannerTranslations } = this.props;
        let imagePreview = null;

        if (bannerTranslations && type === "edit" && bannerImg === null) 
            imagePreview = bannerTranslations[0].image.imageData;
        else
            bannerImg && (imagePreview = bannerImg);

        return (
            languages.map((item, index) => {
                return (
                    <TabPane key={index} tabId={index}>
                        <Field
                            name={`translations[${index}].language`}
                            id={`translations[${index}].language`}
                            component={HiddenElement}
                            hiddenValue={item.value}
                        />
                        <Field
                            name={`translations[${index}].title`}
                            id={`translations[${index}].title`}
                            component={InputElement}
                            label={t('banners:bannerForm->labelTitle')}
                            validate={[required, maxLength256]}
                        />
                        <Field
                            name={`translations[${index}].link`}
                            id={`translations[${index}].link`}
                            component={InputElement}
                            label={t('banners:bannerForm->labelLink')}
                            validate={[required]}
                        />
                        <Field
                            name={`translations[${index}].image.imageData`}
                            id={`translations[${index}].image.imageData`}
                            component={FileInput}
                            label={t("banners:bannerForm->labelImage")}
                            onChange={(e) => this.handleImageUpload(e)}
                            types={IMAGE_TYPE_FORMAT}
                            maxSize={IMAGE_MAX_SIZE}
                            validate={required}
                        />
                        <ImagePreview src={imagePreview} format='data:image/png;base64' />
                        
                    </TabPane>
                )
            })
        );
    }

    renderColGeneral() {
        const { t, initialValues: { isEnabled } } = this.props;

        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('banners:bannerForm->formTitle1')}</h5>
                    </div>
                    <Field
                        name="isEnabled"
                        id="isEnabled"
                        component={CheckBoxElement}
                        defaultChecked={isEnabled}
                        label={t('banners:bannerForm->labelEnabled')}
                        className="colored-click"
                    />
                </CardBody>
            </Card>
        );
    }

    renderColTranslations() {
        const {t} = this.props;
        const {activeTab} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('banners:bannerForm->formTitle2')}</h5>
                    </div>
                    <div className="tabs tabs--justify tabs--bordered-bottom">
                        <div className="tabs__wrap">
                            <Nav tabs>
                                {this.renderTabHeader()}
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                {this.renderTabContent()}
                            </TabContent>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }

    render() {
        const { t, id, btnText, type } = this.props;

        return (
            <Container>
                <form className="form d-block" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Row>
                        <Col md={6}>
                            {this.renderColGeneral()}
                        </Col>
                        <Col md={6}>
                            {this.renderColTranslations()}
                        </Col>
                    </Row>
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit" onClick={() => this.checkErrorInTabs()}>{btnText}</Button>
                        {
                            type === 'edit' && <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:modal->buttonNo')}
                                secondBtnText={t('modal:modal->buttonYes')}
                                title={t('banners:bannerList->modalDeleteTitle')}
                                btn={t('banners:bannerForm->btnDelete')}
                                message={<p>{t('banners:bannerList->modalDeleteMessage')}</p>}
                                handleOnClick={() => this.handleModalDelete(id)}
                            />
                        }
                        <Link className="btn btn-secondary" to="/banners">
                            {t('banners:bannerForm->btnCancel')}
                        </Link>
                    </ButtonToolbar>
                </form>
            </Container>
        );
    }
}

const selector = formValueSelector('bannerForm');

const mapStateToProps = (state) => {
    return {
        id: selector(state, 'id'),
        title: selector(state, 'title'),
        link: selector(state, 'link'),
        imageData: selector(state, 'imageData'),
        formSyncErrors: getFormSyncErrors('bannerForm')(state)
    };
};

const translationWrapped = withTranslation(['content', 'modal'])(BannerForm);

const formWrapped = reduxForm({
    form: 'bannerForm',
})(translationWrapped);


export default connect(mapStateToProps)(formWrapped);