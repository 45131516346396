import React, {PureComponent} from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {Collapse} from 'reactstrap';
import {signOut} from '../../../redux/actions/authActions';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LogoutIcon from 'mdi-react/LogoutIcon';

class TopbarProfile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
    }

    toggle = () => {
        this.setState(prevState => ({collapse: !prevState.collapse}));
    };

    render() {
        const {collapse} = this.state;
        const {t, signOut} = this.props;
        const username = localStorage.getItem('user');
        const avatar = `${process.env.PUBLIC_URL}/img/user_avatar.svg`;
        return (
            <div className="topbar__profile">
                <button type="button" className="topbar__avatar" onClick={this.toggle}>
                    <img className="topbar__avatar-img" src={avatar} alt="avatar"/>
                    <p className="topbar__avatar-name">{username}</p>
                    <DownIcon className="topbar__icon"/>
                </button>
                {collapse && <button type="button" className="topbar__back" onClick={this.toggle}/>}
                <Collapse isOpen={collapse} className="topbar__menu-wrap">
                    <div className="topbar__menu">
                        <div className="topbar__link" onClick={signOut}>
                            <LogoutIcon className="topbar__link-icon"/>
                            <p className="topbar__link-title">{t('topbar->btnLogout')}</p>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}

const translationWrapped = withTranslation("navigation")(TopbarProfile);

export default connect(null, {signOut})(translationWrapped);
