import React from "react";
import {hot, setConfig} from "react-hot-loader";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "bootstrap/dist/css/bootstrap.css";
import "../../shared/scss/app.scss";
import {config as i18nextConfig} from "../../translations";
import Router from "./Router/";
import store from "./store";
import ScrollToTop from "./ScrollToTop";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

i18next.use(LanguageDetector).init(i18nextConfig);

setConfig({
    showReactDomPatchNotification: false,
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loaded: false,
        };
    }

    componentDidMount() {
        window.addEventListener("load", () => {
            this.setState({loading: false});
            setTimeout(() => this.setState({loaded: true}), 500);
        });
    }

    render() {
        const {loaded, loading} = this.state;
        return (
            <>
                <ReactNotification/>
                <Provider store={store}>
                    <BrowserRouter>
                        <I18nextProvider i18n={i18next}>
                            <ScrollToTop>
                                <>
                                    {!loaded && (
                                        <div className={`load${loading ? "" : " loaded"}`}>
                                            <div className='load__icon-wrap'>
                                                <img
                                                    className='img-fluid'
                                                    src={`${process.env.PUBLIC_URL}/img/load.gif`}
                                                    alt='Loading'
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <>
                                        <Router/>
                                    </>
                                </>
                            </ScrollToTop>
                        </I18nextProvider>
                    </BrowserRouter>
                </Provider>
            </>
        );
    }
}

export default hot(module)(App);
