import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {fetchLanguages} from '../../../redux/actions/themeActions';
import i18next from 'i18next';
import {withTranslation} from 'react-i18next';
import {Collapse} from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';

class TopbarLanguage extends React.Component {
    static propTypes = {
        i18n: PropTypes.shape({changeLanguage: PropTypes.func}).isRequired,
    };

    state = {
        collapse: false,
        mainButtonContent: null,
    };

    componentDidMount() {
        const {languages} = this.props;
        if (languages.length > 0) {
            languages.map(language => (
                language.value === i18next.language && this.setState({
                    mainButtonContent:
                        this.renderLanguage(language)
                })
            ));
        } else {
            this.props.fetchLanguages();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages !== this.props.languages) {
            const {languages} = this.props;
            languages.map(language => (
                language.value === i18next.language && this.setState({
                    mainButtonContent:
                        this.renderLanguage(language)
                })
            ));
        }
    }

    renderLanguage(lng) {
        return (
            <span className="topbar__language-btn-title">
                <img src={`/img/flag/${lng.value}.png`} alt={lng.label}/>
                <span>{lng.value.split('-')[0].toUpperCase()}</span>
            </span>
        )
    }

    toggle = () => {
        this.setState(prevState => ({collapse: !prevState.collapse}));
    };

    changeLanguage = (lng) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lng.value);
        document.documentElement.lang = lng.value;
        this.setState({
            mainButtonContent: this.renderLanguage(lng),
            collapse: false
        });
    };

    render() {
        const {mainButtonContent, collapse} = this.state;
        const {languages, done} = this.props;

        if (!done) {
            return <div>Loading...</div>
        }

        return (
            languages.length > 1 &&
            <div className="topbar__collapse topbar__collapse--language">
                <button className="topbar__btn" type="button" onClick={this.toggle}>
                    {mainButtonContent}
                    <DownIcon className="topbar__icon"/>
                </button>
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle}/>}
                <Collapse
                    isOpen={collapse}
                    className="topbar__collapse-content topbar__collapse-content--language"
                >
                    {
                        languages.map((language, index) => {
                            return (
                                <button
                                    key={index}
                                    className="topbar__language-btn"
                                    type="button"
                                    onClick={() => this.changeLanguage(language)}
                                >
                                    {this.renderLanguage(language)}
                                </button>
                            )
                        })
                    }
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.theme.data,
        done: state.theme.done.status
    };
};

export default compose(withTranslation('common'), connect(mapStateToProps, {fetchLanguages}))(TopbarLanguage);