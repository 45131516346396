import axios from 'axios';
import history from '../../history';

export const SIGN_IN_AUTH = 'SIGN_IN_AUTH';
export const SIGN_OUT_AUTH = 'SIGN_OUT_AUTH';
export const PENDING_AUTH = 'PENDING_AUTH';
export const ERROR_AUTH = 'ERROR_AUTH';
export const DONE_AUTH = 'DONE_AUTH';
export const VERIFY_TOKEN_AUTH = 'VERIFY_TOKEN_AUTH';

async function deleteLocaleInfo() {
    const removeItems = ['token', 'user', 'roles'];
    await removeItems.forEach(item => localStorage.removeItem(item));
}

export const signIn = (formValues, prevLocation) => async dispatch => {
    dispatch({ type: PENDING_AUTH });
    try {
        const response = await axios.post('/Account/SignIn', formValues);
        if (response) {
            const token = {
                value: response.data.token,
                expirationDate: response.data.expirationDate
            }
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('user', (response.data.username));
            localStorage.setItem('roles', (response.data.roles));
            dispatch({ type: SIGN_IN_AUTH, payload: response.data });
            dispatch({ type: DONE_AUTH, payload: null });
            prevLocation ? history.replace(prevLocation.from.pathname) : history.replace('/')
        }
    } catch (e) {
        dispatch({ type: ERROR_AUTH, payload: e.message });
    }
};

export const signOut = () => async dispatch => {
    dispatch({ type: PENDING_AUTH });
    try {
        const response = await axios.post('/Account/SignOut');
        if (response) {
            await deleteLocaleInfo();
            dispatch({ type: SIGN_OUT_AUTH, payload: response.data });
            dispatch({ type: DONE_AUTH });
            history.replace('/login');
        }
    } catch (e) {
        dispatch({ type: ERROR_AUTH, payload: e.message });
    }
};

export const verifyToken = () => async dispatch => {
    dispatch({ type: PENDING_AUTH });
    const token = JSON.parse(localStorage.getItem('token'));
    const language = localStorage.getItem('i18nextLng');

    if (language) {
        document.documentElement.lang = language;
    }
    if (token) {
        const expirationDate = new Date(token.expirationDate).getTime();
        const now = new Date().getTime();
        if (now > expirationDate) {
            const removeItems = ['token', 'user'];
            removeItems.forEach(item => localStorage.removeItem(item));
            dispatch({
                type: VERIFY_TOKEN_AUTH, payload: {
                    isAuthenticated: false,
                    username: null
                }
            });
        } else {
            var data = {
                token: token.value,
                expirationDate: new Date(token.expirationDate),
                username: localStorage.getItem('user'),
                roles: localStorage.getItem('roles').split(",")
            }
            const response = await axios.post('/Account/SetSessionToken', data);
            if (response) {
                dispatch({
                    type: VERIFY_TOKEN_AUTH, payload: {
                        isAuthenticated: true,
                        username: localStorage.getItem('user')
                    }
                });
            }
            else {
                dispatch({
                    type: VERIFY_TOKEN_AUTH, payload: {
                        isAuthenticated: false,
                        username: null
                    }
                });
            }
        }
    } else {
        dispatch({
            type: VERIFY_TOKEN_AUTH, payload: {
                isAuthenticated: false,
                username: null
            }
        });
    }
    dispatch({ type: DONE_AUTH });
};