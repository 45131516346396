import React from "react";
import { connect } from "react-redux";
import { fetchOptions, removeOptions } from "../../redux/actions/selectOptionActions";
import { createBanner } from "../../redux/actions/bannersActions";
import { fetchLanguages } from "../../redux/actions/themeActions";
import { store } from "react-notifications-component";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import BannerForm from "./BannerForm";
import { Card, CardBody, Container } from "reactstrap";
import Alert from "../../shared/components/common/Alert";
import { Link } from "react-router-dom";

class BannerCreate extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message && this.props.message) {
      store.addNotification({
        title: this.props.t("notification:success->title"),
        message: this.props.t("notification:success->message"),
        type: "success",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 3000,
          showIcon: true,
        },
      });
    }
  }

    onSubmit = (formValues) => {
    this.props.createBanner(formValues);
  };

  renderError = () => {
    const { t, languagesError } = this.props;

    return (
      <Container>
        <Card>
          <CardBody>
            <Alert color='danger' className='alert--bordered' icon>
              <>
                <p className='bold-text'>{t("alert:danger->title")}</p>
                <p>{t("alert:danger->message", { error: languagesError })}</p>
                <Link to='/banners'>{t("alert:danger->goBack")}</Link>
              </>
            </Alert>
          </CardBody>
        </Card>
      </Container>
    );
  };

  render() {
    const {
      t,
      languages,
      languagesDone,
      languagesError,
      } = this.props;

    if (!languagesDone) {
      return (
        <div className='d-flex justify-content-center'>
          <div>
            <img
              width='100'
              height='100'
              src={`${process.env.PUBLIC_URL}/img/load.gif`}
              alt='Loading'
            />
          </div>
        </div>
      );
    }
    if (languagesError) {
      return this.renderError(); 
    }

      return (
         
      <BannerForm
        onSubmit={this.onSubmit}
        btnText={t("banners:bannerForm->btnCreate")}
        languages={languages}
        initialValues={{ isEnabled: true }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languages: state.theme.data,
    languagesDone: state.theme.done.status,
    languagesError: state.theme.error,
  };
};

export default compose(
  withTranslation(["content", "notification"]),
  connect(mapStateToProps, {
    createBanner,
    fetchLanguages,
  })
)(BannerCreate);