import React, {PureComponent} from 'react';
import {Alert} from 'reactstrap';
import PropTypes from 'prop-types';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import {withTranslation} from 'react-i18next';

class AlertComponent extends PureComponent {
    static propTypes = {
        color: PropTypes.string,
        icon: PropTypes.bool,
        className: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.array
        ]).isRequired,
    };

    static defaultProps = {
        color: '',
        icon: false,
        className: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
        };
    }

    render() {
        const {visible} = this.state;

        const {color, className, icon, children} = this.props;
        let Icon;

        switch (color) {
            case 'info':
                Icon = <InformationOutlineIcon/>;
                break;
            case 'success':
                Icon = <ThumbUpOutlineIcon/>;
                break;
            case 'warning':
                Icon = <CommentAlertOutlineIcon/>;
                break;
            case 'danger':
                Icon = <CloseCircleOutlineIcon/>;
                break;
            default:
                break;
        }

        if (visible) {
            return (
                <Alert color={color} className={className} isOpen={visible}>
                    {icon && <div className="alert__icon">{Icon}</div>}
                    <div className="alert__content">
                        {children}
                    </div>
                </Alert>
            );
        }
    }
}

export default withTranslation()(AlertComponent)