import React, {PureComponent} from "react";
import {SketchPicker} from "react-color";
import {Popover} from "reactstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

class SketchColorPickerField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        disabled: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: props.value,
            rgb: {
                r: 246,
                g: 129,
                b: 110,
                a: 1,
            },
            active: false,
        };
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            displayColorPicker: !prevState.displayColorPicker,
            active: !prevState.active,
        }));
    };

    handleChange = (color) => {
        const {onChange} = this.props;
        this.setState({color: color.hex, rgb: color.rgb});
        onChange(color);
    };

    handleClose = () => {
        this.setState({displayColorPicker: false, active: false});
    }

    render() {
        const {id, disabled} = this.props;
        const {active, color, displayColorPicker, rgb} = this.state;

        return (
            <>
                <div id={`popover-${id}`}>
                    <div className={`color-picker__wrapper ${displayColorPicker ? 'd-block' : 'd-none'}`} onClick={this.handleClose}/>
                </div>
                <div className='color-picker'>
                    <button
                        type='button'
                        className={`color-picker__button${active ? ' active' : ''} ${disabled ? ' color-picker__disabled' : ''}`}
                        onClick={this.handleClick}
                        id={id}
                    >
                        <p className='color-picker__color'>{color}</p>
                        <div className='color-picker__color-view' style={{backgroundColor: color}}/>
                    </button>
                    <Popover
                        isOpen={displayColorPicker}
                        target={id}
                        placement='bottom'
                        className='color-picker__popover'
                        container={`popover-${id}`}
                    >
                        <SketchPicker color={rgb} onChange={this.handleChange}/>
                    </Popover>
                </div>
            </>
        );
    }
}

const SketchColorPicker = (props) => {
    const {input, meta: {touched, error, submitFailed}, label, id, disabled} = props;
    const {t} = useTranslation('validation');
    return (
        <div className='form__form-group'>
            <span className='form__form-group-label'>{label}</span>
            <div className='form__form-group-field'>
                <div className='form__form-group-input-wrap'>
                    <SketchColorPickerField {...input} id={id} disabled={disabled}/>
                    {touched && error && submitFailed && (
                        <span className='form__form-group-error'>{t(`validate->${error}`)}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

SketchColorPicker.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    disabled: PropTypes.bool
};

export default SketchColorPicker;
