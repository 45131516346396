import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, getFormSyncErrors} from 'redux-form';
import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import {required, maxLength16, maxLength150, maxLength256} from '../../shared/validations';
import classnames from 'classnames';
import {withTranslation} from "react-i18next";
import InputElement from '../../shared/components/form/Input';
import CheckBoxElement from '../../shared/components/form/CheckBox';
import AutoComplete from '../../shared/components/form/AutoComplete';
import TextEditor from '../../shared/components/form/TextEditor';
import HiddenElement from '../../shared/components/form/Hidden';
import ModalConfirmation from '../../shared/components/common/ModalConfirmation';

class ContentForm extends React.Component {
    state = {
        activeTab: 0,
        languages: this.props.languages
    };

    onSubmit = (formValues) => {
        this.props.onSubmit({...formValues, groupKey: formValues.groupKey.value});
    };

    handleModalDelete = id => {
        this.props.onDelete(id);
    };

    createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    resetContentField(e) {
        if (e.target !== undefined) {
            for (let i = 0; i < this.state.languages.length; i++) {
                this.props.change(`translations[${i}].value`, null);
                this.props.untouch(`translations[${i}].value`);
            }
        }
    };

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;

        if (formSyncErrors.translations) {
            formSyncErrors.translations.some((item, i) => {
                if (item) {
                    this.state.activeTab !== i && this.setState({activeTab: i});
                    return true;
                }
            })
        }
    }

    renderTabHeader() {
        const {activeTab, languages} = this.state;

        return (
            languages.map((item, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({active: activeTab === index})}
                            onClick={() => {
                                this.toggleTab(index);
                            }}
                        >
                            {item.label}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderTabContent() {
        const {languages} = this.state;
        const {t, isHtml} = this.props;

        return (
            languages.map((item, index) => {
                return (
                    <TabPane key={index} tabId={index}>
                        <Field
                            name={`translations[${index}].language`}
                            id={`translations[${index}].language`}
                            component={HiddenElement}
                            hiddenValue={item.value}
                        />
                        <Field
                            name={`translations[${index}].friendlyName`}
                            id={`translations[${index}].friendlyName`}
                            component={InputElement}
                            label={t('contentForm->labelFriendlyName')}
                            validate={[required, maxLength150]}
                        />
                        <Field
                            name="isHtml"
                            component={CheckBoxElement}
                            label={t('contentForm->labelIsHtml')}
                            id={`isHtml_${item.value}`}
                            className="colored-click"
                            defaultChecked={this.props.initialValues.isHtml}
                            onChange={e => this.resetContentField(e)}
                        />
                        {
                            isHtml ?
                                (<Field
                                    name={`translations[${index}].value`}
                                    component={TextEditor}
                                    label={t('contentForm->labelValue')}
                                    id={`translations[${index}].value`}
                                    validate={required}
                                />)
                                :
                                (<Field
                                    name={`translations[${index}].value`}
                                    component={InputElement}
                                    label={t('contentForm->labelValue')}
                                    id={`translations[${index}].value`}
                                    validate={required}
                                />)
                        }
                    </TabPane>
                )
            })
        );
    }

    renderColGeneral() {
        const {t, initialValues: {isEnabled}} = this.props;
        const options = this.props.options.map(item => {
            return this.createOption(item);
        });

        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('contentForm->formTitle1')}</h5>
                    </div>
                    <Field
                        name="isEnabled"
                        id="isEnabled"
                        component={CheckBoxElement}
                        defaultChecked={isEnabled}
                        label={t('contentForm->labelEnabled')}
                        className="colored-click"
                    />
                    <Field
                        name="key"
                        id="key"
                        component={InputElement}
                        label={t('contentForm->labelKey')}
                        validate={[required, maxLength256]}
                    />
                    <Field
                        name="groupKey"
                        id="groupKey"
                        component={AutoComplete}
                        options={options}
                        label={t('contentForm->labelGroupKey')}
                        validate={[required, maxLength256]}
                    />
                </CardBody>
            </Card>
        );
    }

    renderColTranslations() {
        const {t} = this.props;
        const {activeTab} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('contentForm->formTitle2')}</h5>
                    </div>
                    <div className="tabs tabs--justify tabs--bordered-bottom">
                        <div className="tabs__wrap">
                            <Nav tabs>
                                {this.renderTabHeader()}
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                {this.renderTabContent()}
                            </TabContent>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }

    render() {
        const {t, id, btnText, type} = this.props;

        return (
            <Container>
                <form className="form d-block" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Row>
                        <Col md={6}>
                            {this.renderColGeneral()}
                        </Col>
                        <Col md={6}>
                            {this.renderColTranslations()}
                        </Col>
                    </Row>
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit" onClick={() => this.checkErrorInTabs()}>{btnText}</Button>
                        {
                            type === 'edit' && <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:modal->buttonNo')}
                                secondBtnText={t('modal:modal->buttonYes')}
                                title={t('contentList->modalDeleteTitle')}
                                btn={t('contentForm->btnDelete')}
                                message={<p>{t('contentList->modalDeleteMessage')}</p>}
                                handleOnClick={() => this.handleModalDelete(id)}
                            />
                        }
                        <Link className="btn btn-secondary" to="/content">
                            {t('contentForm->btnCancel')}
                        </Link>
                    </ButtonToolbar>
                </form>
            </Container>
        );
    }
}

const selector = formValueSelector('contentForm');

const mapStateToProps = (state) => {
    return {
        id: selector(state, 'id'),
        isHtml: selector(state, 'isHtml'),
        formSyncErrors: getFormSyncErrors('contentForm')(state)
    };
};

const translationWrapped = withTranslation(['content', 'modal'])(ContentForm);

const formWrapped = reduxForm({
    form: 'contentForm',
})(translationWrapped);

export default connect(mapStateToProps)(formWrapped);