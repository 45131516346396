import axios from 'axios';
import history from '../../history';
import {saveAs} from 'file-saver';

export const FETCH_VOUCHERS = 'FETCH_VOUCHERS';
export const FETCH_VOUCHER = 'FETCH_VOUCHER';
export const FETCH_VOUCHER_LIST = 'FETCH_VOUCHER_LIST';
export const CREATE_VOUCHER = 'CREATE_VOUCHER';
export const EDIT_VOUCHER = 'EDIT_VOUCHER';
export const TOGGLE_VOUCHER = 'TOGGLE_VOUCHER';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const DONE_VOUCHER = 'DONE_VOUCHER';
export const PENDING_VOUCHER = 'PENDING_VOUCHER';
export const ERROR_VOUCHER = 'ERROR_VOUCHER';
export const IMPORT_VOUCHER = 'IMPORT_VOUCHER';
export const FETCH_VOUCHER_VERSION_LIST = 'FETCH_VOUCHER_VERSION_LIST';
export const EXPORT_VOUCHER_CAMPAIGN = 'EXPORT_VOUCHER_CAMPAIGN';
export const FETCH_VOUCHERS_LIST = 'FETCH_VOUCHERS_LIST';
export const LIST_VOUCHERS_EXPORT = 'LIST_VOUCHERS_EXPORT';

const initialFilter = {
    name: null,
    partnerType: 2,
    hasActiveCampaigns: true,
    campaignBenefitType: null,
    beginDate: null,
    endDate: null,
    dateTime: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null
    },
    highlighted: null,
    isEnabled: null,
    showFinished: false,
    orderColumn: null,
    orderDesc: null
};

export const fetchVoucherCampaigns = (page, pageSize, filters = initialFilter) => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const params = {
            "page": page,
            "pageSize": pageSize,
            "name": filters.name,
            "hasActiveCampaigns": filters.hasActiveCampaigns,
            "isEnabled": filters.isEnabled,
            "partnerType": 2
        };
        const response = await axios.get('/Partners/GetVouchersCampaignPartners', {
            headers: { language: localStorage.getItem('i18nextLng')},
            params: params
        });
        if (response) {
            dispatch({type: FETCH_VOUCHERS, payload: response.data});
            dispatch({type: DONE_VOUCHER, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const fetchVoucherCampaignList = (partnerId, page, pageSize, filters = initialFilter) => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const params = {
            "partnerId": partnerId,
            "page": page,
            "pageSize": pageSize,
            "showFinished": filters.showFinished,
            "name": filters.name,
            "campaignBenefitType": filters.campaignBenefitType,
            "beginDate": filters.beginDate,
            "endDate": filters.endDate,
            "monday": filters.dateTime && filters.dateTime.monday,
            "tuesday": filters.dateTime && filters.dateTime.tuesday,
            "wednesday": filters.dateTime && filters.dateTime.wednesday,
            "thursday": filters.dateTime && filters.dateTime.thursday,
            "friday": filters.dateTime && filters.dateTime.friday,
            "saturday": filters.dateTime && filters.dateTime.saturday,
            "sunday": filters.dateTime && filters.dateTime.sunday,
            "highlighted": filters.highlighted,
            "isEnabled": filters.isEnabled,
            "partnerType": 2,
            "orderColumn": filters.orderColumn,
            "orderDesc": filters.orderDesc
        };
        const response = await axios.get('/VoucherCampaigns/GetVoucherCampaigns', {
            headers: { language: localStorage.getItem('i18nextLng')},
            params: params
        });
        if (response) {
            dispatch({type: FETCH_VOUCHER_LIST, payload: response.data});
            dispatch({type: DONE_VOUCHER, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};


export const fetchVoucherCampaign = id => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.get(`/VoucherCampaigns/GetById/${id}`);
        if (response) {
            dispatch({type: FETCH_VOUCHER, payload: response.data});
            dispatch({type: DONE_VOUCHER});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};


export const createVoucherCampaign = formValues => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.post('/VoucherCampaigns', formValues);
        if (response) {
            dispatch({type: CREATE_VOUCHER, payload: 'operationSuccess'});
            let pathname = history.location.pathname;
            history.replace(pathname.split('/create')[0]);
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const editVoucherCampaign = formValues => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.put('/VoucherCampaigns', formValues);
        if (response) {
            dispatch({type: EDIT_VOUCHER, payload: 'operationSuccess'});
            let pathname = history.location.pathname;
            history.replace(pathname.split('/edit')[0]);
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const toggleVoucherCampaign = formValues => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.patch('/VoucherCampaigns', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_VOUCHER, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const deleteVoucherCampaign = (id, type) => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.delete(`/VoucherCampaigns/${id}`);
        if (response) {
            dispatch({type: DELETE_VOUCHER, payload: 'operationSuccess'});
            if (type === 'form') {
                let pathname = history.location.pathname;
                history.replace(pathname.split('/edit')[0]);
            }
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const importVouchers = formValues => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.post('/VoucherPackage/ImportVouchers', formValues);
        if (response) {
            dispatch({type: IMPORT_VOUCHER, payload: response.data});
            dispatch({type: DONE_VOUCHER, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const fetchVouchersVersionList = id => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.get(`/VoucherPackage/GetVersionsList/${id}`);
        if (response) {
            dispatch({type: FETCH_VOUCHER_VERSION_LIST, payload: response.data});
            dispatch({type: DONE_VOUCHER});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const exportVoucherCampaign = (partnerId, page, pageSize, filters = initialFilter) => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const params = {
            "partnerId": partnerId,
            "page": page,
            "pageSize": pageSize,
            "showFinished": filters.showFinished,
            "name": filters.name,
            "campaignBenefitType": filters.campaignBenefitType,
            "beginDate": filters.beginDate,
            "endDate": filters.endDate,
            "monday": filters.dateTime && filters.dateTime.monday,
            "tuesday": filters.dateTime && filters.dateTime.tuesday,
            "wednesday": filters.dateTime && filters.dateTime.wednesday,
            "thursday": filters.dateTime && filters.dateTime.thursday,
            "friday": filters.dateTime && filters.dateTime.friday,
            "saturday": filters.dateTime && filters.dateTime.saturday,
            "sunday": filters.dateTime && filters.dateTime.sunday,
            "highlighted": filters.highlighted,
            "isEnabled": filters.isEnabled,
            "partnerType": 2,
            "orderColumn": filters.orderColumn,
            "orderDesc": filters.orderDesc
        };
        const response = await axios.get('/VoucherCampaigns/ExportVoucherCampaigns', {
            headers: { language: localStorage.getItem('i18nextLng')},
            responseType: 'arraybuffer',
            params: params
        });
        if (response) {
            let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, 'ExportedCampaigns.xlsx');
            dispatch({type: EXPORT_VOUCHER_CAMPAIGN, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const fetchVouchersList = formValues => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.post('/VoucherCampaigns/ListVouchers', formValues);
        if (response) {
            dispatch({type: FETCH_VOUCHERS_LIST, payload: response.data});
            dispatch({type: DONE_VOUCHER});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};

export const listVouchersExport = formValues => async dispatch => {
    dispatch({type: PENDING_VOUCHER});
    try {
        const response = await axios.post('/VoucherCampaigns/ListVouchersExport', formValues, { responseType: 'arraybuffer', headers: { language: localStorage.getItem('i18nextLng')}});
        if (response) {
            let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, 'ExportedVouchers.xlsx');
            dispatch({type: EXPORT_VOUCHER_CAMPAIGN, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_VOUCHER, payload: e.message});
    }
};