import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';

class MainWrapper extends PureComponent {
    static propTypes = {
        theme: ThemeProps.isRequired,
        children: PropTypes.element.isRequired,
    };

    render() {
        const {
            theme, children,
        } = this.props;

        return (
            <div className={`${theme.className} ltr-support`} dir="ltr">
                <div className="wrapper">
                    {children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme
    }
};

export default (connect(mapStateToProps)(MainWrapper));
