import axios from 'axios';

export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const PENDING_THEME = 'PENDING_THEME';
export const DONE_THEME = 'DONE_THEME';
export const ERROR_THEME = 'ERROR_THEME';

export function changeThemeToDark() {
    localStorage.setItem('theme', 'theme-dark');
    return {
        type: CHANGE_THEME_TO_DARK,
    };
}

export function changeThemeToLight() {
    localStorage.setItem('theme', 'theme-light');
    return {
        type: CHANGE_THEME_TO_LIGHT,
    };
}

export const fetchLanguages = () => async dispatch => {
    dispatch({type: PENDING_THEME});
    try {
        const response = await axios.get('/Settings/GetLanguages');
        if (response) {
            dispatch({type: FETCH_LANGUAGES, payload: response.data});
            dispatch({type: DONE_THEME});
        }
    } catch (e) {
        dispatch({type: ERROR_THEME, payload: e.message});
    }
};
