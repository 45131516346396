import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

export class FileInputField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.shape({
                name: PropTypes.string,
            }),
            PropTypes.string,
        ]),
        types: PropTypes.array,
        maxSize: PropTypes.number,
        disabled: PropTypes.bool
    };

    state = {
        error: {
            mimeType: null,
            fileSize: null
        }
    };

    checkMimeTypes = (file, types) => {
        if (types.includes(file.type)) {
            this.setState({error: {mimeType: null}});
        } else {
            this.setState({error: {mimeType: 'typeNotSupported'}});
            return false;
        }
        return true;
    };

    checkFileSize = (file, maxSize) => {
        if (file.size > maxSize) {
            this.setState({error: {fileSize: 'fileTooLarge'}});
            return false;
        } else {
            this.setState({error: {fileSize: null}});
        }
        return true;
    };

    handleOnChange(e, onChange, types, maxSize) {
        e.preventDefault();
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (this.checkMimeTypes(file, types) && this.checkFileSize(file, maxSize)) {
                onChange({file: file, name: file.name});
            } else {
                onChange();
            }
        }
    }

    render() {
        const {t, onChange, name, value, types, maxSize, disabled} = this.props;
        const {error} = this.state;
        return (
            <div className="form__form-group-file">
                <label htmlFor={name} className={`${disabled && 'file-input__disabled'}`}>{t('common:fileUpload')}</label>
                <span>{value.name}</span>
                <input
                    type="file"
                    accept={types}
                    name={name}
                    id={name}
                    onChange={e => this.handleOnChange(e, onChange, types, maxSize)}
                />
                {
                    (error.mimeType) &&
                    <span className="form__form-group-error pl-0">{t(`validate->${error.mimeType}`)}</span>
                }
                {
                    (error.fileSize) &&
                    <span className="form__form-group-error pl-0">{t(`validate->${error.fileSize}`)}</span>
                }
            </div>
        );
    }
}

const FileInput = (props) => {
    const {input, meta: {touched, error, submitFailed}, label, types, maxSize, disabled} = props;
    const {t} = useTranslation(['validation', 'common']);
    return (
        <div className="form__form-group">
            <span className="form__form-group-label">
                {label}
            </span>
            <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                    <FileInputField 
                        {...input} 
                        t={t} 
                        types={types} 
                        maxSize={maxSize}
                        disabled={disabled}
                    />
                    {touched && error && submitFailed &&
                    <span className="form__form-group-error">{t(`validate->${error}`)}</span>}
                </div>
            </div>
        </div>
    );
};

FileInput.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        submitFailed: PropTypes.bool
    }),
    label: PropTypes.string,
    types: PropTypes.array,
    maxSize: PropTypes.number,
    disabled: PropTypes.bool
};

export default FileInput;
