import React from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <></>
        </components.DropdownIndicator>
    );
};

class AutocompleteField extends React.Component {
    state = {
        menuIsOpen: false,
        isLoading: false
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            label: PropTypes.string,
        })),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                label: PropTypes.string,
            }),
        ]).isRequired,
        disableCreateOption: PropTypes.bool,
        async: PropTypes.bool,
        handleInputChange: PropTypes.func,
        isDisabled: PropTypes.bool,
        isClearable: PropTypes.bool
    };

    createOption = label => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    handleCreate = value => {
        this.setState({ isLoading: true });
        setTimeout(() => {
            const { options } = this.state;
            const newOption = this.createOption(value);
            this.setState({
                isLoading: false,
                options: [...options, newOption],
                value: newOption,
            });
            this.props.onChange(newOption);
        }, 1000);
    };

    handleChange = selectedOption => {
        const { onChange } = this.props;
        onChange(selectedOption);
    };

    handleInputChange = value => {
        value ? this.toggleMenuIsOpen(true) : this.toggleMenuIsOpen(false);
        this.props.async && this.props.handleInputChange(value.trim());
    };

    toggleMenuIsOpen = status => {
        this.setState({ menuIsOpen: status });
    };

    render() {
        const { t, value, name, placeholder, disableCreateOption, options, async, isDisabled, isClearable } = this.props;
        const { isLoading, menuIsOpen } = this.state;

        return (
            <>
                <CreatableSelect
                    className="react-select"
                    classNamePrefix="react-select"
                    name={name}
                    value={value}
                    components={{ DropdownIndicator }}
                    onChange={this.handleChange}
                    options={options}
                    isDisabled={this.props.isLoading || isLoading || isDisabled}
                    isLoading={this.props.isLoading || isLoading}
                    blurInputOnSelect={true}
                    onInputChange={async ? debounce(this.handleInputChange, 200) : this.handleInputChange}
                    menuIsOpen={menuIsOpen}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    onCreateOption={this.handleCreate}
                    isValidNewOption={() => !disableCreateOption}
                    formatCreateLabel={value => `${t('common:labelCreateNew')} "${value}"`}
                    noOptionsMessage={() => t('common:labelSelectNoOptions')}
                />

            </>
        );
    }
}

const AutoComplete = props => {
    const { input, meta: { touched, error, submitFailed }, options, placeholder, className, label, disableCreateOption, async, handleInputChange, isLoading = false, disabled = false, isClearable = false } = props;
    const { t } = useTranslation(['validation', 'common']);

    return (
        <div className="form__form-group">
            <span className="form__form-group-label">{label}</span>
            <div className="form__form-group-field">
                <div className={`form__form-group-input-wrap ${className}`}>
                    <AutocompleteField
                        {...input}
                        options={options}
                        placeholder={placeholder}
                        disableCreateOption={disableCreateOption}
                        isLoading={isLoading}
                        async={async}
                        handleInputChange={handleInputChange}
                        isDisabled={disabled}
                        isClearable={isClearable}
                        t={t}
                    />
                    {touched && error && submitFailed &&
                        <span className="form__form-group-error">{t(`validation:validate->${error}`)}</span>}
                </div>
            </div>
        </div>
    );
};

AutoComplete.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        label: PropTypes.string,
    })),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    disableCreateOption: PropTypes.bool,
    async: PropTypes.bool,
    handleInputChange: PropTypes.func,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool
};

AutoComplete.defaultProps = {
    meta: null,
    options: [],
    placeholder: '',
    className: '',
    label: ''
};

export default AutoComplete;
