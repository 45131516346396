import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import WeatherNightIcon from "mdi-react/WeatherNightIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import KeyboardOutlineIcon from "mdi-react/KeyboardOutlineIcon";
import CarIcon from "mdi-react/CarIcon";
import ModalConfirmation from "../../../shared/components/common/ModalConfirmation";
import { clearCache, fetchMenu } from "../../../redux/actions/sidebarActions";
import { store } from "react-notifications-component";

class SidebarContent extends React.Component {
  state = {
    toggleDarkMode: localStorage.getItem("theme") === "theme-dark" || false,
  };

  componentDidMount() {
    this.props.fetchMenu();
  }

  changeTheme(e) {
    const { changeToLight, changeToDark } = this.props;
    if (e.target.checked) {
      changeToDark();
      this.setState({ toggleDarkMode: true });
    } else {
      changeToLight();
      this.setState({ toggleDarkMode: false });
    }
  }

  handleModalDeleteCache() {
    this.props.clearCache();
    store.addNotification({
      title: this.props.t("notification:successCache->title"),
      message: this.props.t("notification:successCache->message"),
      type: "success",
      insert: "bottom",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "zoomOut"],
      dismiss: {
        duration: 3000,
        showIcon: true,
      },
    });
    }

  render() {
    const { t, type, menuDone, menus } = this.props;
    const { toggleDarkMode } = this.state;

    const iconComponents = {
        CalendarMonthIcon: CalendarMonthIcon,
        CarIcon: CarIcon,
        KeyboardOutlineIcon: KeyboardOutlineIcon
    };

    function addIconElement(IconTagString){
        const IconTag = iconComponents[IconTagString];
        return <IconTag />;
    }

    if (!menuDone) {
      return (
        <div className='container'>
          <h1>De momento n&atilde;o &eacute; poss&iacute;vel aceder ao Backoffice.</h1>
          <h3>Por favor tente mais tarde.</h3>
        </div>
      );
    }
    return (
      <>
        <div className='sidebar__content'>
          <ul className='sidebar__block'>
            {menus.map((item, index) => {
              return (             
                  <div key={index}>
                    {item.isVisible ? (
                        item.pages ? (
                        <SidebarCategory icon={addIconElement(item.icon)} title={item.name}>
                          {item.pages.map((page, i) => {
                            return (
                              <div key={i}>
                                {page.pages ? (
                                  <SidebarCategory  submenu title={page.name}>
                                    {page.pages.map((p, l) => (
                                      <SidebarLink key={l} title={p.name} route={p.path} />
                                    ))}
                                  </SidebarCategory>
                                ) : (
                                  <SidebarLink title={page.name} route={page.path} />
                                )}
                              </div>
                            );
                          })}
                        </SidebarCategory>
                      ) : (
                        <SidebarLink icon={addIconElement(item.icon)} title={item.name} route={item.path} />
                      )
                    ) : null}
                  </div>
              );
            })}
          </ul>
        </div>
        <ul className='sidebar__block list-unstyled'>
          <li>
            <div className='sidebar__link'>
              <span className='sidebar__link-icon'>
                <WeatherNightIcon />
              </span>
              <p className='sidebar__link-title align-items-center'>
                {t("sidebar->darkTheme")}
                <label className='toggle-btn customizer__toggle ml-2' htmlFor='changeTheme'>
                  <input
                    className='toggle-btn__input'
                    type='checkbox'
                    name='changeTheme'
                    id='changeTheme'
                    checked={toggleDarkMode}
                    onChange={(e) => this.changeTheme(e)}
                  />
                  <span className='toggle-btn__input-label' />
                </label>
              </p>
            </div>
          </li>
          <li className='mt-4 ml-4'>
            <ModalConfirmation
              backdrop='static'
              color='danger'
              firstBtnText={t("modal:modal->buttonNo")}
              secondBtnText={t("modal:modal->buttonYes")}
              title={t("sidebar->modalDeleteTitle")}
              btn={t("sidebar->cleanCache")}
              message={<p>{t("sidebar->modalDeleteMessage")}</p>}
              handleOnClick={() => this.handleModalDeleteCache()}
              closeOnAction
              buttonDisabled={false}
            />
          </li>
        </ul>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    done: state.sidebar.done.status,
    message: state.sidebar.done.message,
    menus: state.sidebar.data.menus,
    menuDone: state.sidebar.data.done,
  };
};

export default compose(
  withTranslation(["navigation"]),
  connect(mapStateToProps, {
    clearCache,
    fetchMenu,
  })
)(SidebarContent);
