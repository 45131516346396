import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ContentList from '../../../Content/ContentList';
import ContentCreate from '../../../Content/ContentCreate';
import ContentEdit from '../../../Content/ContentEdit';

export default () => (
    <Switch>
        <Route path="/content" exact component={ContentList}/>
        <Route path="/content/create" component={ContentCreate}/>
        <Route path="/content/edit/:id" component={ContentEdit}/>
    </Switch>
);