import React, {Component} from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';

const ToolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline'],
    },
};

export default class WysiwygElement extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const contentBlock = htmlToDraft(props.value);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
            };
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    onEditorStateChange = (editorState) => {
        const {onChange} = this.props;

        this.setState({
            editorState,
        });
        if (onChange) {
            onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }
    };

    render() {
        const {editorState} = this.state;
        const {disabled} = this.props;
        
        return (
            <div className="text-editor">
                <Editor
                    editorState={editorState}
                    wrapperClassName="text-editor__wrapper"
                    editorClassName={`${disabled ? 'text-editor__disabled' : ''}`}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={ToolbarOptions}
                    readOnly={disabled}
                />
            </div>
        );
    }
}
