import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BannerForm from "./BannerForm";
import { fetchOptions, removeOptions } from "../../redux/actions/selectOptionActions";
import { fetchLanguages } from "../../redux/actions/themeActions";
import { fetchBanner, editBanner, deleteBanner } from "../../redux/actions/bannersActions";
import { store } from "react-notifications-component";
import { Container, Card, CardBody } from "reactstrap";
import Alert from "../../shared/components/common/Alert";
import { Link } from "react-router-dom";

class BannerEdit extends React.Component {
    componentDidMount() {
        this.props.fetchBanner(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    onSubmit = (formValues) => {
        this.props.editBanner(formValues);
    };

    onDelete = (id) => {
        this.props.deleteBanner(id, "form");
    };

    renderError = () => {
        const { t, bannerError, languagesError } = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>
                                    {t("alert:danger->message", {
                                        error: bannerError || languagesError,
                                    })}
                                </p>
                                <Link to='/banners'>{t("alert:danger->goBack")}</Link>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {
            t,
            bannerDone,
            bannerError,
            banner,
            languages,
            languagesDone,
            languagesError,
        } = this.props;

        if (!bannerDone || !languagesDone) {
            return (
                <div className='d-flex justify-content-center'>
                    <div>
                        <img
                            width='100'
                            height='100'
                            src={`${process.env.PUBLIC_URL}/img/load.gif`}
                            alt='Loading'
                        />
                    </div>
                </div>
            );
        }

        if (bannerError || languagesError) {
            return this.renderError();
        }

        let bannerCopy = { ...banner };

        return (
            <BannerForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                languages={languages}
                type='edit'
                btnText={t("banners:bannerForm->btnSave")}
                initialValues={bannerCopy}
                bannerTranslations={bannerCopy.translations}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        banner: state.banners.data.banners[ownProps.match.params.id],
        bannerDone: state.banners.done.status,
        bannerError: state.banners.error,
        message: state.banners.done.message,
        languages: state.theme.data,
        languagesDone: state.theme.done.status,
        languagesError: state.theme.error,
    };
};

export default compose(
    withTranslation(["content", "notification", "alert"]),
    connect(mapStateToProps, {
        fetchBanner,
        editBanner,
        deleteBanner,
        fetchLanguages,
    })
)(BannerEdit);
