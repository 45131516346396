import React from "react";
import { connect } from "react-redux";
import { createService } from "../../../redux/actions/serviceActions";
import { fetchOptions, removeOptions } from "../../../redux/actions/selectOptionActions";
import { fetchLanguages } from "../../../redux/actions/themeActions";
import { store } from "react-notifications-component";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import ServiceForm from "./ServiceForm";
import { Card, CardBody, Container } from "reactstrap";
import Alert from "../../../shared/components/common/Alert";
import { Link } from "react-router-dom";

class ServiceCreate extends React.Component {
  componentDidMount() {
    this.props.fetchOptions("/Categories/GetAll", { headers: { language: "pt-PT" } });
  }

  componentDidUpdate() {
    if (this.props.message) {
      store.addNotification({
        title: this.props.t("success->title"),
        message: this.props.t("success->message"),
        type: "success",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 3000,
          showIcon: true,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.removeOptions();
  }

  onSubmit = (formValues) => {
    this.props.createService(formValues);
  };

  renderError = () => {
    const { t, servicesCatsOptionsError, languagesError } = this.props;

    return (
      <Container>
        <Card>
          <CardBody>
            <Alert color='danger' className='alert--bordered' icon>
              <>
                <p className='bold-text'>{t("alert:danger->title")}</p>
                <p>
                  {t("alert:danger->message", {
                    error: servicesCatsOptionsError || languagesError,
                  })}
                </p>
                <Link to='/vvservices/services'>{t("alert:danger->goBack")}</Link>
              </>
            </Alert>
          </CardBody>
        </Card>
      </Container>
    );
  };

  render() {
    const {
      t,
      servicesCatOptions,
      servicesCatsOptionsDone,
      servicesCatsOptionsError,
      languages,
      languagesDone,
      languagesError,
    } = this.props;
    if (!servicesCatsOptionsDone || !languagesDone) {
      return (
        <div className='d-flex justify-content-center'>
          <div>
            <img
              width='100'
              height='100'
              src={`${process.env.PUBLIC_URL}/img/load.gif`}
              alt='Loading'
            />
          </div>
        </div>
      );
    }

    if (servicesCatsOptionsError || languagesError) {
      return this.renderError();
    }

    return (
      <ServiceForm
        onSubmit={this.onSubmit}
        btnText={t("serviceForm->btnCreate")}
        catsOptions={Object.values(servicesCatOptions)}
        languages={languages}
        initialValues={{ isEnabled: true, color: "#ffffff" }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    servicesCatOptions: state.selectOptions.data,
    servicesCatsOptionsDone: state.selectOptions.done,
    servicesCatsOptionsError: state.selectOptions.error,
    languages: state.theme.data,
    languagesDone: state.theme.done.status,
    languagesError: state.theme.error,
  };
};

export default compose(
  withTranslation(["services", "notification"]),
  connect(mapStateToProps, {
    fetchOptions,
    removeOptions,
    createService,
    fetchLanguages,
  })
)(ServiceCreate);
