import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ServiceForm from "./ServiceForm";
import {fetchLanguages} from "../../../redux/actions/themeActions";
import {fetchOptions, removeOptions} from "../../../redux/actions/selectOptionActions";
import {fetchService, editService, deleteService} from "../../../redux/actions/serviceActions";
import {store} from "react-notifications-component";
import {Container, Card, CardBody} from "reactstrap";
import Alert from "../../../shared/components/common/Alert";
import {Link} from "react-router-dom";

class ServiceEdit extends React.Component {
    componentDidMount() {
        this.props.fetchService(this.props.match.params.id);
        this.props.fetchOptions("/Categories/GetAll", {headers: {language: "pt-PT"}});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    componentWillUnmount() {
        this.props.removeOptions();
    }

    onSubmit = (formValues) => {
        this.props.editService(formValues);
    };

    onDelete = (id) => {
        this.props.deleteService(id, "form");
    };

    renderError = () => {
        const {t, serviceError, servicesCatOptionsError, languagesError} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>{t("alert:danger->message", {error: serviceError || servicesCatOptionsError || languagesError})}</p>
                                <Link to='/vvservices/services'>{t("alert:danger->goBack")}</Link>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {
            t,
            serviceDone,
            serviceError,
            services,
            languages,
            languagesDone,
            languagesError,
            servicesCatOptions,
            servicesCatOptionsDone,
            servicesCatOptionsError
        } = this.props;

        if (!serviceDone || !languagesDone || !servicesCatOptionsDone) {
            return (
                <div className='d-flex justify-content-center'>
                <div>
                  <img
                    width='100'
                    height='100'
                    src={`${process.env.PUBLIC_URL}/img/load.gif`}
                    alt='Loading'
                  />
                </div>
              </div>
            );
        }

        if (serviceError || servicesCatOptionsError || languagesError) {
            return this.renderError();
        }

        let servicesCopy = {...services};
        servicesCatOptions.map(item => parseInt(item.key) === services.categoryId && (servicesCopy.categoryId = {
            label: item.value,
            value: item.key
        }));

        !servicesCopy.colorEnabled && (servicesCopy.color = '#ffffff');

        return (
            <ServiceForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                languages={languages}
                catsOptions={Object.values(servicesCatOptions)}
                type='edit'
                btnText={t("services:serviceForm->btnSave")}
                initialValues={servicesCopy}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        servicesCatOptions: state.selectOptions.data,
        servicesCatOptionsDone: state.selectOptions.done,
        servicesCatOptionsError: state.selectOptions.error,
        services: state.services.data.services[ownProps.match.params.id],
        serviceDone: state.services.done.status,
        serviceError: state.services.error,
        message: state.services.done.message,
        languages: state.theme.data,
        languagesDone: state.theme.done.status,
        languagesError: state.theme.error
    };
};
export default compose(
    withTranslation(["services", "notification", "alert"]),
    connect(mapStateToProps, {
        fetchService,
        editService,
        deleteService,
        fetchLanguages,
        fetchOptions,
        removeOptions,
    })
)(ServiceEdit);
