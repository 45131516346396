import createNumberMask from "text-mask-addons/dist/createNumberMask";

export const DATATABLE_DARK_MODE_STYLES = {
    progress: {
        style: {
            color: "#dddddd",
            backgroundColor: "#232329",
        },
    },
}

export const numberMask = (prefix, suffix) => {
    return createNumberMask({
        prefix: prefix,
        suffix: suffix,
        allowDecimal: true,
        allowLeadingZeroes: false,
        thousandsSeparatorSymbol: ' ',
        decimalSymbol: ','
    });
} 

export const SEARCH_TIMEOUT = 1500;
    