import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

export default class SidebarCategory extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        icon: PropTypes.element,
        isNew: PropTypes.bool,
        submenu: PropTypes.bool,
        children: PropTypes.arrayOf(PropTypes.element).isRequired,
    };

    static defaultProps = {
        isNew: false,
        submenu: false
    };

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
    }

    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    render() {
        const {
            title, icon, isNew, submenu, children,
        } = this.props;
        const { collapse } = this.state;
        const categoryClass = classNames({
            'sidebar__category-wrap': true,
            'sidebar__category-wrap--open': collapse,
        });

        return (
            <div className={categoryClass}>
                <button type="button" className="sidebar__link sidebar__category" onClick={this.toggle}>
                    {icon ? <span className="sidebar__link-icon">{icon}</span> : ''}
                    <p className="sidebar__link-title">{title}
                        {isNew && <span className="sidebar__category-new" />}
                    </p>
                    <span className={`sidebar__category-icon${submenu ? '-submenu' : ''} ${collapse ? 'open' : ''}`}><ChevronRightIcon/></span>
                </button>
                <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
                    <ul className="sidebar__submenu">
                        <div>
                            {children}
                        </div>
                    </ul>
                </Collapse>
            </div>
        );
    }
}
