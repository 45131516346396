import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ServiceList from '../../../VVServices/Services/ServiceList';
import ServiceCreate from '../../../VVServices/Services/ServiceCreate';
import ServiceEdit from '../../../VVServices/Services/ServiceEdit';

export default () => (
    <Switch>
        <Route path="/vvservices/services" exact component={ServiceList}/>
        <Route path="/vvservices/services/create" component={ServiceCreate}/>
        <Route path="/vvservices/services/edit/:id" component={ServiceEdit}/>
    </Switch>
);