import React from "react";
import { connect } from "react-redux";
import { fetchOptions, removeOptions } from "../../redux/actions/selectOptionActions";
import { createContent } from "../../redux/actions/contentActions";
import { fetchLanguages } from "../../redux/actions/themeActions";
import { store } from "react-notifications-component";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import ContentForm from "./ContentForm";
import { Card, CardBody, Container } from "reactstrap";
import Alert from "../../shared/components/common/Alert";
import { Link } from "react-router-dom";

class ContentCreate extends React.Component {
  componentDidMount() {
    this.props.fetchOptions("/Resources/GetGroupKeys", { headers: { language: "pt-PT" } });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message && this.props.message) {
      store.addNotification({
        title: this.props.t("notification:success->title"),
        message: this.props.t("notification:success->message"),
        type: "success",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 3000,
          showIcon: true,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.removeOptions();
  }

  onSubmit = (formValues) => {
    this.props.createContent(formValues);
  };

  renderError = () => {
    const { t, groupKeyError, languagesError } = this.props;

    return (
      <Container>
        <Card>
          <CardBody>
            <Alert color='danger' className='alert--bordered' icon>
              <>
                <p className='bold-text'>{t("alert:danger->title")}</p>
                <p>{t("alert:danger->message", { error: groupKeyError || languagesError })}</p>
                <Link to='/content'>{t("alert:danger->goBack")}</Link>
              </>
            </Alert>
          </CardBody>
        </Card>
      </Container>
    );
  };

  render() {
    const {
      t,
      groupKeyDone,
      groupKeyOptions,
      groupKeyError,
      languages,
      languagesDone,
      languagesError,
    } = this.props;
    if (!groupKeyDone || !languagesDone) {
      return (
        <div className='d-flex justify-content-center'>
          <div>
            <img
              width='100'
              height='100'
              src={`${process.env.PUBLIC_URL}/img/load.gif`}
              alt='Loading'
            />
          </div>
        </div>
      );
    }
    if (groupKeyError || languagesError) {
      return this.renderError();
    }

    return (
      <ContentForm
        onSubmit={this.onSubmit}
        options={groupKeyOptions}
        btnText={t("content:contentForm->btnCreate")}
        languages={languages}
        initialValues={{ isEnabled: true }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groupKeyOptions: Object.values(state.selectOptions.data),
    groupKeyDone: state.selectOptions.done,
    groupKeyError: state.selectOptions.error,
    languages: state.theme.data,
    languagesDone: state.theme.done.status,
    languagesError: state.theme.error,
  };
};

export default compose(
  withTranslation(["content", "notification"]),
  connect(mapStateToProps, {
    fetchOptions,
    removeOptions,
    createContent,
    fetchLanguages,
  })
)(ContentCreate);
