import axios from "axios";

export const FETCH_SELECT_OPTIONS = 'FETCH_SELECT_OPTIONS';
export const PENDING_SELECT_OPTIONS = 'PENDING_SELECT_OPTIONS';
export const DONE_SELECT_OPTIONS = 'DONE_SELECT_OPTIONS';
export const ERROR_SELECT_OPTIONS = 'ERROR_SELECT_OPTIONS';

export const fetchOptions = (url, headers) => async dispatch => {
    dispatch({type: PENDING_SELECT_OPTIONS});
    let response;
    try {
        response = await axios.get(url, headers);
    } catch (e) {
        dispatch({type: ERROR_SELECT_OPTIONS, payload: e.message});
    }
    if (response) {
        dispatch({type: FETCH_SELECT_OPTIONS, payload: response.data});
        dispatch({type: DONE_SELECT_OPTIONS});
    }
};

export const removeOptions = () => async dispatch => {
    dispatch({type: PENDING_SELECT_OPTIONS});
};