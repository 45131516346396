import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Modal} from 'reactstrap';
import classNames from 'classnames';
import {connect} from 'react-redux';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';

class ModalConfirmation extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        message: PropTypes.element,
        color: PropTypes.string.isRequired,
        colored: PropTypes.bool,
        header: PropTypes.bool,
        btn: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string
        ]).isRequired,
        backdrop: PropTypes.string,
        outline: PropTypes.bool,
        closeOnAction: PropTypes.bool,
        btnTitle: PropTypes.string,
        disabled: PropTypes.bool,
        buttonDisabled: PropTypes.bool
    };

    static defaultProps = {
        title: '',
        message: '',
        colored: false,
        header: false,
        buttonDisabled: true
    };

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            disabled: false
        };
    }

    toggleModal = () => {
        this.setState(prevState => ({modal: !prevState.modal}));
    };

    toggleButtonDisable = () => {
       
        this.setState(prevState => ({disabled: !prevState.disabled}));
    };

    render() {
        const {
            color, btn, title, message, colored, header, outline, backdrop, firstBtnText, secondBtnText, handleOnClick, closeOnAction, theme, btnTitle, buttonDisabled
        } = this.props;
        const {modal, disabled} = this.state;
        let Icon;

        switch (color) {
            case 'primary':
                Icon = <span className="lnr lnr-pushpin modal__title-icon"/>;
                break;
            case 'success':
                Icon = <span className="lnr lnr-thumbs-up modal__title-icon"/>;
                break;
            case 'warning':
                Icon = <span className="lnr lnr-flag modal__title-icon"/>;
                break;
            case 'danger':
                Icon = <CloseCircleOutlineIcon className="modal__title-icon"/>;
                break;
            default:
                break;
        }
        const modalClass = classNames({
            'modal-dialog--colored': colored,
            'modal-dialog--header': header,
        });

        return (
            <>
                <Button title={btnTitle} outline={outline} color={color} onClick={this.toggleModal} disabled={this.props.disabled}>{btn}</Button>
                <Modal
                    isOpen={modal}
                    toggle={this.toggleModal}
                    backdrop={backdrop}
                    modalClassName={`ltr-support`}
                    className={`modal-dialog--${color} ${modalClass} ${theme}`}
                >
                    <div className="modal__header">
                        <button className="modal__close-btn" type="button" onClick={this.toggleModal}><CloseIcon/></button>
                        {header ? '' : Icon}
                        <h4 className="text-modal  modal__title">{title}</h4>
                    </div>
                    <div className="modal__body">
                        {message}
                    </div>
                    <ButtonToolbar className="modal__footer">
                        <Button className="modal_cancel" onClick={this.toggleModal}
                                disabled={disabled}>{firstBtnText}</Button>
                        <Button className="modal_ok" outline={colored} color={color} onClick={() => {
                            handleOnClick();
                            if(this.props.buttonDisabled === true) {
                                this.toggleButtonDisable();
                            }
                           
                            closeOnAction && this.toggleModal();
                        }}
                                disabled={disabled}>{secondBtnText}</Button>
                    </ButtonToolbar>
                </Modal>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        theme: state.theme.className,
    };
};

export default connect(mapStateToProps)(ModalConfirmation);