import React from "react";
import PropTypes from "prop-types";

const ImagePreview = ({src, format}) => {
    const imgSrc = format + ', ' + src;
    
    if (src && src.length) {
        return (
            <div className="form__form-group">
                <div className="img-preview">
                    <img alt="preview" src={imgSrc}/>
                </div>
            </div>
        );
    }

    return null;
};

ImagePreview.propTypes = {
    src: PropTypes.string,
    format: PropTypes.string
};

export default ImagePreview;
