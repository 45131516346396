import React from 'react';
import PropTypes from 'prop-types';
import {Badge} from 'reactstrap';
import {NavLink} from 'react-router-dom';

const SidebarLink = ({title, icon, newLink, route, onClick}) => (
    <li>
        <NavLink
            to={route}
            onClick={onClick}
            activeClassName="sidebar__link-active"
        >
            <div className="sidebar__link">
                {icon && <span className="sidebar__link-icon">{icon}</span>}
                <p className="sidebar__link-title">
                    {title}
                    {newLink && <Badge className="sidebar__link-badge"><span>New</span></Badge>}
                </p>
            </div>
        </NavLink>
    </li>
);

SidebarLink.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element,
    newLink: PropTypes.bool,
    route: PropTypes.string,
    onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
    icon: null,
    newLink: false,
    route: '/',
    onClick: () => {
    },
};

export default SidebarLink;
