import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class LoadingElement extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isLoading: false
    };

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return (
                <div className="loading-screen">
                    <img
                        src={`${process.env.PUBLIC_URL}/img/load.gif`}
                        alt="Loading"
                    />
                </div>
            );
        }

        return (
            <>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme.className,
    };
};

export default connect(mapStateToProps)(LoadingElement);
