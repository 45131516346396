export const required = value => value ? undefined : 'required';
export const requiredTextEditor = value => value && value.trim() !== "<p></p>"  ? undefined : 'required';
export const requiredOneOfField = value => value ? undefined : 'requiredOneOfField';
export const requiredOneItem = value => value ? undefined : 'requiredOneItem';
export const maxLength = max => value =>
    value && value.length > max ? `maxLength-${max}` : undefined;
export const maxLength12 = maxLength(12);
export const maxLength16 = maxLength(16);
export const maxLength50 = maxLength(50);
export const maxLength100 = maxLength(100);
export const maxLength150 = maxLength(150);
export const maxLength175 = maxLength(175);
export const maxLength256 = maxLength(256);

export const minLength = min => value =>
    value && value.length < min ? `minLength-${min}` : undefined;
export const minLength11 = minLength(11);

export const number = value =>
    value && isNaN(Number(value)) ? 'isNumber' : undefined;

export const minValue = min => value =>
    value && value < min ? `O valor mínimo é ${min}` : undefined;
export const minValue0 = minValue(0);
export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined;
export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined;
export const phoneNumber = value =>
    value && !/^(0|[1-9][0-9]{9})$/i.test(value)
        ? 'Invalid phone number, must be 10 digits'
        : undefined;

export const url = value =>
    value && !value.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi)
        ? 'invalidUrl'
        : undefined;

export const customUrl = value =>
    value && !value.match(/^([A-z0-9-_+]+):+\/\/([A-z0-9-_+]+\/)*([A-z0-9])+((\.[^\s]{2,})|)$/gi)
        ? 'invalidUrl'
        : undefined;

export const greaterDate = value => value ? undefined : 'greaterDate';
export const lowerDateThanToday = value => value ? undefined : 'lowerDateThanToday';
export const percentage = value =>
    value && !/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/g.test(value)
        ? 'percentageOutOfRange'
        : undefined;
export const invalidDaysUntilUse = value => value ? 'invalidDaysUntilUse' : undefined;
export const coordinates = value =>
    value && !/^(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g.test(value)
        ? 'invalidCoordinates'
        : undefined;