import React from "react";
import {connect} from "react-redux";
import {
    Field,
    reduxForm,
    formValueSelector,
    FieldArray,
    arrayPush,
    arrayRemove,
    getFormSyncErrors,
} from "redux-form";
import {Link} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import {required, requiredOneOfField, customUrl} from "../../../shared/validations";
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import {ICON_TYPE_FORMAT, ICON_MAX_SIZE} from "./variables";
import MinusBox from "mdi-react/MinusBoxIcon";
import InputElement from "../../../shared/components/form/Input";
import CheckBoxElement from "../../../shared/components/form/CheckBox";
import SelectElement from "../../../shared/components/form/Select";
import ModalConfirmation from "../../../shared/components/common/ModalConfirmation";
import HiddenElement from "../../../shared/components/form/Hidden";
import SketchColorPicker from "../../../shared/components/form/SketchColorPicker";
import FileInput from "../../../shared/components/form/FileInput";
import ImagePreview from "../../../shared/components/form/ImagePreview";

class ServiceForm extends React.Component {
    state = {
        activeTab: 0,
        languages: this.props.languages,
        catsOptions: this.props.catsOptions,
        icon: null,
        iconFilterActive: null,
        iconFilterInactive: null,
        iconWithoutBackground: null
    };

    componentDidUpdate() {
        const {showInCatalog, showInMap, change} = this.props;
        showInCatalog || showInMap ? change("serviceTypeCheck", "true") : change("serviceTypeCheck", "");
    }

    onSubmit = (formValues) => {
        const {languages, type, initialValues} = this.props;
        const {icon} = this.state;
        const {iconFilterActive} = this.state;
        const { iconFilterInactive } = this.state;
        const { iconWithoutBackground } = this.state;

        let formToPost = {
            translations: [],
            icon: {},
            iconFilterActive: {},
            iconFilterInactive: {},
            iconWithoutBackground: {}
        };
        formToPost = {
            ...formToPost,
            isEnabled: formValues.isEnabled,
            categoryId: parseInt(formValues.categoryId.value),
            order: parseInt(formValues.order),
            colorEnabled: formValues.colorEnabled,
        };

        if (icon) {
            formToPost.icon.imageData = icon;
            type === 'edit' && (formToPost.icon.id = initialValues.icon.id);
        } else {
            formToPost.icon = initialValues.icon;
        }

         if (iconFilterActive) {
             formToPost.iconFilterActive.imageData = iconFilterActive;
             type === 'edit' && (formToPost.iconFilterActive.id = initialValues.iconFilterActive.id);
         } else {
             formToPost.iconFilterActive = initialValues.iconFilterActive;
         }

         if (iconFilterInactive) {
             formToPost.iconFilterInactive.imageData = iconFilterInactive;
             type === 'edit' && (formToPost.iconFilterInactive.id = initialValues.iconFilterInactive.id);
         } else {
             formToPost.iconFilterInactive = initialValues.iconFilterInactive;
        }

        if (iconWithoutBackground) {
            formToPost.iconWithoutBackground.imageData = iconWithoutBackground;
            if (type === 'edit' && initialValues.iconWithoutBackground !== null) {
                formToPost.iconWithoutBackground.id = initialValues.iconWithoutBackground.id;
            }
        } else {
            formToPost.iconWithoutBackground = initialValues.iconWithoutBackground;
        }

        formValues.colorEnabled && (formToPost.color1 = formValues.color1.hex || formValues.color1)  && (formToPost.color2 = formValues.color2.hex || formValues.color2);
        

        languages.map((item, i) => {
            formToPost.translations.push({
                name: formValues.translations[i].name,
                language: item.value,
            });
        });

        if (formValues.showInCatalog) {
            if (formValues.isComplementary) {
                formToPost.isComplementary = formValues.isComplementary;
            }
            if (formValues.toggleFavoriteEnabled) {
                formToPost.toggleFavoriteEnabled = formValues.toggleFavoriteEnabled;
                languages.map((item, i) => {
                    formToPost.translations[i].toggleFavoriteText = formValues.translations[i].toggleFavoriteText;
                });
            }
            if (formValues.activationEnabled) {
                formToPost.activationEnabled = formValues.activationEnabled;
                formToPost.activationUrl = formValues.activationUrl;
                languages.map((item, i) => {
                    formToPost.translations[i].activationText = formValues.translations[i].activationText;
                    formToPost.translations[i].activationLabel = formValues.translations[i].activationLabel;
                });
            }
            if (formValues.accessEnabled) {
                formToPost.accessEnabled = formValues.accessEnabled;
                formToPost.accessUrl = formValues.accessUrl;
                languages.map((item, i) => {
                    formToPost.translations[i].accessLabel = formValues.translations[i].accessLabel;
                });
            }
            if (formValues.faqEnabled) {
                formToPost.faqEnabled = formValues.faqEnabled;
                formToPost.faqUrl = formValues.faqUrl;
            }

            languages.map((item, i) => {
                formToPost.translations[i].lead = formValues.translations[i].lead;
                formToPost.translations[i].description = formValues.translations[i].description;
                formToPost.translations[i].bodyTitle = formValues.translations[i].bodyTitle;
                if (formValues.translations[i].bodyItems) {
                    formValues.translations[i].bodyItems.map((item, j) => {
                        if (item && item.text) {
                            formToPost.translations[i].bodyItems = formValues.translations[i].bodyItems;
                            formToPost.translations[i].bodyItems[j].order = j + 1;
                        }
                    })
                }
                formToPost.translations[i].bodyFooter = formValues.translations[i].bodyFooter;
            });
        }
        formToPost.showInMap = formValues.showInMap;
        formToPost.showInCatalog = formValues.showInCatalog;

        if (type === "edit") {
            formToPost.id = formValues.id;
            languages.map((item, i) => {
                formToPost.translations[i].id = formValues.translations[i].id;
            });
        }

        this.props.onSubmit(formToPost);
    };

    handleModalDelete = (id) => {
        this.props.onDelete(id);
    };

    handleImageUpload(e) {
        if (e) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            reader.onloadend = (e) => this.setState({icon: e.target.result.split(",")[1]});
        }
    }

    handleImageUpload2(e) {
        if (e) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            reader.onloadend = (e) => this.setState({iconFilterActive: e.target.result.split(",")[1]});
        }
    }

    handleImageUpload3(e) {
        if (e) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            reader.onloadend = (e) => this.setState({iconFilterInactive: e.target.result.split(",")[1]});
        }
    }

    handleImageUpload4(e) {
        if (e) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            reader.onloadend = (e) => this.setState({ iconWithoutBackground: e.target.result.split(",")[1] });
        }
    }

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;

        if (formSyncErrors.translations) {
            formSyncErrors.translations.some((item, i) => {
                if (item) {
                    this.state.activeTab !== i && this.setState({activeTab: i});
                    return true;
                }
            })
        }
    }

    renderFields = (props) => {
        const {fields, t, onFieldDelete, onFieldChange} = props;
        /*if no input, then add a new default blank one*/
        !fields.length && fields.push();

        return (
            <>
                {fields.map((item, i) => (
                    <div className='form__form-group mb-0' key={i}>
                        <div className='form__form-group-field'>
                            <Field
                                name={`${item}.text`}
                                id={`${item}.text`}
                                type='text'
                                label={t("serviceForm->labelBodyItemsText") + (i + 1)}
                                component={InputElement}
                                validate={fields.length > 1 ? [required] : []}
                                onChange={e => onFieldChange(e)}
                            />
                            {i >= 1 && (
                                <button
                                    type='button'
                                    className={`form__form-group-button`}
                                    onClick={() => onFieldDelete(i)}
                                >
                                    <MinusBox/>
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </>
        );
    };

    checkServiceType() {
        const {showInCatalog, showInMap, change} = this.props;
        showInCatalog || showInMap ? change("serviceTypeCheck", "true") : change("serviceTypeCheck", null);
    }

    checkTranslationsBody(e, index) {
        const {change} = this.props;
        !/^ *$/.test(e.target.value.trim()) ? change(`translations[${index}].body`, 'true') : change(`translations[${index}].body`, null);
    }

    renderTabHeader() {
        const {activeTab, languages} = this.state;

        return languages.map((item, index) => {
            return (
                <NavItem key={index}>
                    <NavLink className={classnames({active: activeTab === index})} onClick={() => this.toggleTab(index)}>
                        {item.label}
                    </NavLink>
                </NavItem>
            );
        });
    }

    renderTabContent() {
        const {languages} = this.state;
        const {
            t,
            showInCatalog,
            toggleFavoriteEnabled,
            activationEnabled,
            accessEnabled,
        } = this.props;

        return languages.map((item, index) => {
            return (
                <TabPane key={index} tabId={index}>
                    <Field
                        name={`translations[${index}].language`}
                        id={`translations[${index}].language`}
                        component={HiddenElement}
                        hiddenValue={item.value}
                    />
                    <Field
                        name={`translations[${index}].name`}
                        id={`translations[${index}].name`}
                        component={InputElement}
                        label={t("serviceForm->labelName")}
                        validate={required}
                    />
                    {showInCatalog && (
                        <>
                            <Field
                                name={`translations[${index}].lead`}
                                id={`translations[${index}].lead`}
                                component={InputElement}
                                label={t("serviceForm->labelLead")}
                                validate={required}
                            />
                            <Field
                                name={`translations[${index}].description`}
                                id={`translations[${index}].description`}
                                component={InputElement}
                                label={t("serviceForm->labelDescription")}
                                validate={required}
                            />
                            <p className='bold-text'>{t("serviceForm->labelHowToUse")}</p>
                            <Field
                                name={`translations[${index}].bodyTitle`}
                                id={`translations[${index}].bodyTitle`}
                                component={InputElement}
                                label={t("serviceForm->labelBodyTitle")}
                                onChange={e => this.checkTranslationsBody(e, index)}
                                validate={requiredOneOfField}
                            />
                            <FieldArray
                                name={`translations[${index}].bodyItems`}
                                component={this.renderFields}
                                onFieldDelete={index => languages.map((item, i) =>
                                    this.props.dispatch(arrayRemove("serviceForm", `translations[${i}].bodyItems`, index))
                                )}
                                t={t}
                                onFieldChange={e => this.checkTranslationsBody(e, index)}
                            />
                            <Button
                                color='primary'
                                type='button'
                                onClick={() => languages.map((item, i) =>
                                    this.props.dispatch(arrayPush("serviceForm", `translations[${i}].bodyItems`, {}))
                                )}
                            >
                                Add
                            </Button>
                            <Field
                                name={`translations[${index}].bodyFooter`}
                                id={`translations[${index}].bodyFooter`}
                                component={InputElement}
                                label={t("serviceForm->labelBodyFooter")}
                                onChange={e => this.checkTranslationsBody(e, index)}
                            />
                            {toggleFavoriteEnabled && (
                                <>
                                    <p className='bold-text'>{t("serviceForm->labelFavorite")}</p>
                                    <Field
                                        name={`translations[${index}].toggleFavoriteText`}
                                        id={`translations[${index}].toggleFavoriteText`}
                                        component={InputElement}
                                        label={t("serviceForm->labelToggleFavoriteText")}
                                        validate={required}
                                    />
                                </>
                            )}
                            {activationEnabled && (
                                <>
                                    <p className='bold-text'>{t("serviceForm->labelActivationButton")}</p>
                                    <Field
                                        name={`translations[${index}].activationText`}
                                        id={`translations[${index}].activationText`}
                                        component={InputElement}
                                        label={t("serviceForm->labelActivationText")}
                                        validate={required}
                                    />
                                    <Field
                                        name={`translations[${index}].activationLabel`}
                                        id={`translations[${index}].activationLabel`}
                                        component={InputElement}
                                        label={t("serviceForm->labelActivationLabel")}
                                        validate={required}
                                    />
                                </>
                            )}
                            {accessEnabled && (
                                <>
                                    <p className='bold-text'>{t("serviceForm->labelAccessButton")}</p>
                                    <Field
                                        name={`translations[${index}].accessLabel`}
                                        id={`translations[${index}].accessLabel`}
                                        component={InputElement}
                                        label={t("serviceForm->labelAccessLabel")}
                                        validate={required}
                                    />
                                </>
                            )}
                        </>
                    )}
                </TabPane>
            );
        });
    }

    renderColGeneral() {
        const {
            t,
            showInCatalog,
            colorEnabled,
            activationEnabled,
            accessEnabled,
            faqEnabled,
            initialValues,
        } = this.props;
        const { catsOptions, icon, iconFilterActive, iconFilterInactive, iconWithoutBackground} = this.state;
        const options = catsOptions.map((item) => {
            return {
                label: item.value,
                value: item.key,
            };
        });

        let iconPreview, iconPreviewActive, iconPreviewInactive, iconPreviewWithoutBackground = null;
            

        (initialValues.icon && (initialValues.icon === icon || !icon)) ? iconPreview = initialValues.icon.imageData : icon && (iconPreview = icon);

        (initialValues.iconFilterActive && (initialValues.iconFilterActive === iconFilterActive || !iconFilterActive)) ? iconPreviewActive = initialValues.iconFilterActive.imageData : iconFilterActive && (iconPreviewActive = iconFilterActive);

        (initialValues.iconFilterInactive && (initialValues.iconFilterInactive === iconFilterInactive || !iconFilterInactive)) ? iconPreviewInactive = initialValues.iconFilterInactive.imageData : iconFilterInactive && (iconPreviewInactive = iconFilterInactive);

        (initialValues.iconWithoutBackground && (initialValues.iconWithoutBackground === iconWithoutBackground || !iconWithoutBackground)) ? iconPreviewWithoutBackground = initialValues.iconWithoutBackground.imageData : iconWithoutBackground && (iconPreviewWithoutBackground = iconWithoutBackground);

        return (
            <Card>
                <CardBody>
                    <div className='card__title'>
                        <h5 className='bold-text'>{t("serviceForm->formTitle1")}</h5>
                    </div>
                    <Field
                        name='isEnabled'
                        id='isEnabled'
                        component={CheckBoxElement}
                        defaultChecked={initialValues.isEnabled}
                        label={t("serviceForm->labelEnabled")}
                        className='colored-click'
                    />
                    <Field
                        name='categoryId'
                        id='categoryId'
                        component={SelectElement}
                        options={options}
                        label={t("serviceForm->labelCategory")}
                        validate={required}
                    />
                    <Field
                        name='order'
                        id='order'
                        component={InputElement}
                        label={t("serviceForm->labelOrder")}
                        numeric
                        validate={required}
                    />
                    <div className='form__form-group'>
                        <span className='form__form-group-label'>{t("serviceForm->labelServiceType")}</span>
                        <Field
                            name='serviceTypeCheck'
                            id='serviceTypeCheck'
                            component={HiddenElement}
                            hiddenValue=''
                            validate={required}
                        />
                        <div className='form__form-group-field'>
                            <Field
                                id="showInCatalog"
                                name='showInCatalog'
                                component={CheckBoxElement}
                                label={t("serviceForm->labelCatalog")}
                                className='colored-click'
                                defaultChecked={initialValues.showInCatalog}
                                onChange={() => this.checkServiceType()}
                            />
                            <Field
                                id="showInMap"
                                name='showInMap'
                                component={CheckBoxElement}
                                label={t("serviceForm->labelMap")}
                                className='colored-click'
                                defaultChecked={initialValues.showInMap}
                                onChange={() => this.checkServiceType()}
                            />
                        </div>
                    </div>
                    <Field
                        name='colorEnabled'
                        id='colorEnabled'
                        component={CheckBoxElement}
                        label={t("serviceForm->labelColorEnabled")}
                        defaultChecked={initialValues.colorEnabled}
                        className='colored-click'
                    />
                    {colorEnabled && (
                        <>
                        <Field
                            name='color1'
                            id='color1'
                            component={SketchColorPicker}
                            label={t("serviceForm->labelColor")}
                            validate={required}
                        />
                        
                        <Field
                            name='color2'
                            id='color2'
                            component={SketchColorPicker}
                            label={t("serviceForm->labelColorEnd")}
                            validate={required}
                        />
                        </>
                    )}
                    <Field
                        name='icon'
                        component={FileInput}
                        label={t("serviceForm->labelIcon")}
                        onChange={(e) => this.handleImageUpload(e)}
                        types={ICON_TYPE_FORMAT}
                        maxSize={ICON_MAX_SIZE}
                        validate={required}
                    />
                    <ImagePreview src={iconPreview} format='data:image/svg+xml;base64' />

                    <Field
                        name='iconFilterActive'
                        component={FileInput}
                        label={t("serviceForm->labelIconActive")}
                        onChange={(e) => this.handleImageUpload2(e)}
                        types={ICON_TYPE_FORMAT}
                        maxSize={ICON_MAX_SIZE}
                        validate={required}
                    />
                        <ImagePreview src={iconPreviewActive} format='data:image/svg+xml;base64'/>

                    <Field
                        name='iconFilterInactive'
                        component={FileInput}
                        label={t("serviceForm->labelIconInactive")}
                        onChange={(e) => this.handleImageUpload3(e)}
                        types={ICON_TYPE_FORMAT}
                        maxSize={ICON_MAX_SIZE}
                        validate={required}
                    />
                      <ImagePreview src={iconPreviewInactive} format='data:image/svg+xml;base64'/>

                    <Field
                        name='iconWithoutBackground'
                        component={FileInput}
                        label={t("serviceForm->labelIconWithoutBackground")}
                        onChange={(e) => this.handleImageUpload4(e)}
                        types={ICON_TYPE_FORMAT}
                        maxSize={ICON_MAX_SIZE}
                    />
                    <ImagePreview src={iconPreviewWithoutBackground} format='data:image/svg+xml;base64' />

                    {showInCatalog && (
                        <>
                            <Field
                                name='isComplementary'
                                id='isComplementary'
                                component={CheckBoxElement}
                                label={t("serviceForm->labelIsComplementary")}
                                defaultChecked={initialValues.isComplementary}
                                className='colored-click'
                            />
                            <Field
                                name='toggleFavoriteEnabled'
                                id='toggleFavoriteEnabled'
                                component={CheckBoxElement}
                                label={t("serviceForm->labelToggleFavoriteEnabled")}
                                defaultChecked={initialValues.toggleFavoriteEnabled}
                                className='colored-click'
                            />
                            <Field
                                name='activationEnabled'
                                id='activationEnabled'
                                component={CheckBoxElement}
                                label={t("serviceForm->labelActivationEnabled")}
                                defaultChecked={initialValues.activationEnabled}
                                className='colored-click'
                            />
                            {activationEnabled && (
                                <Field
                                    name='activationUrl'
                                    id='activationUrl'
                                    component={InputElement}
                                    label={t("serviceForm->labelUrl")}
                                    type='url'
                                    validate={[required, customUrl]}
                                />
                            )}
                            <Field
                                name='accessEnabled'
                                id='accessEnabled'
                                component={CheckBoxElement}
                                label={t("serviceForm->labelAccessEnabled")}
                                defaultChecked={initialValues.accessEnabled}
                                className='colored-click'
                            />
                            {accessEnabled && (
                                <Field
                                    name='accessUrl'
                                    id='accessUrl'
                                    component={InputElement}
                                    label={t("serviceForm->labelUrl")}
                                    type='url'
                                    validate={[required, customUrl]}
                                />
                            )}
                            <Field
                                name='faqEnabled'
                                id='faqEnabled'
                                component={CheckBoxElement}
                                label={t("serviceForm->labelFaqEnabled")}
                                defaultChecked={initialValues.faqEnabled}
                                className='colored-click'
                            />
                            {faqEnabled && (
                                <Field
                                    name='faqUrl'
                                    id='faqUrl'
                                    component={InputElement}
                                    label={t("serviceForm->labelUrl")}
                                    type='url'
                                    validate={[required, customUrl]}
                                />
                            )}
                        </>
                    )}
                </CardBody>
            </Card>
        );
    }

    renderColTranslations() {
        const {t} = this.props;
        const {activeTab} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className='card__title'>
                        <h5 className='bold-text'>{t("serviceForm->formTitle2")}</h5>
                    </div>
                    <div className='tabs tabs--justify tabs--bordered-bottom'>
                        <div className='tabs__wrap'>
                            <Nav tabs>{this.renderTabHeader()}</Nav>
                            <TabContent activeTab={activeTab}>{this.renderTabContent()}</TabContent>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    render() {
        const {t, handleSubmit, id, btnText, type} = this.props;
        return (
            <Container>
                <form className='form d-block' onSubmit={handleSubmit(this.onSubmit)} noValidate>
                    <Row>
                        <Col md={6}>{this.renderColGeneral()}</Col>
                        <Col md={6}>{this.renderColTranslations()}</Col>
                    </Row>
                    <ButtonToolbar className='form__button-toolbar'>
                        <Button color='primary' type='submit' onClick={() => this.checkErrorInTabs()}>
                            {btnText}
                        </Button>
                        {type === "edit" && (
                            <ModalConfirmation
                                backdrop='static'
                                color='danger'
                                firstBtnText={t("modal:modal->buttonNo")}
                                secondBtnText={t("modal:modal->buttonYes")}
                                title={t("serviceList->modalDeleteTitle")}
                                btn={t("serviceForm->btnDelete")}
                                message={<p>{t("serviceList->modalDeleteMessage")}</p>}
                                handleOnClick={() => this.handleModalDelete(id)}
                            />
                        )}
                        <Link className='btn btn-secondary' to='/vvservices/services'>
                            {t("serviceForm->btnCancel")}
                        </Link>
                    </ButtonToolbar>
                </form>
            </Container>
        );
    }
}

const selector = formValueSelector("serviceForm");

const mapStateToProps = (state) => {
    return {
        id: selector(state, "id"),
        showInCatalog: selector(state, "showInCatalog"),
        showInMap: selector(state, "showInMap"),
        colorEnabled: selector(state, "colorEnabled"),
        activationEnabled: selector(state, "activationEnabled"),
        accessEnabled: selector(state, "accessEnabled"),
        faqEnabled: selector(state, "faqEnabled"),
        toggleFavoriteEnabled: selector(state, "toggleFavoriteEnabled"),
        formSyncErrors: getFormSyncErrors('serviceForm')(state)
    };
};

const translationWrapped = withTranslation(["services", "modal"])(ServiceForm);

const formWrapped = reduxForm({
    form: "serviceForm",
})(translationWrapped);

export default connect(mapStateToProps, {arrayPush})(formWrapped);
