import React from 'react';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import {Col, Container, Row, Card, CardBody, Button, ButtonToolbar, ButtonGroup} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {withTranslation} from 'react-i18next';
import DataTable from 'react-data-table-component';
import {connect} from 'react-redux';
import {fetchBanners, toggleBanner, deleteBanner} from '../../redux/actions/bannersActions';
import ModalConfirmation from '../../shared/components/common/ModalConfirmation';
import {store} from "react-notifications-component";
import 'animate.css';
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from './variables';
import { DATATABLE_DARK_MODE_STYLES, SEARCH_TIMEOUT } from '../variables';
import LoadingElement from "../../shared/components/common/Loading";


class BannerList extends React.Component {
    state = {
        data: [],
        filterText: '',
        timeout: 0,
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        newItems: []
    };

    componentDidMount() {
        this.props.fetchBanners(CURRENT_PAGE, PAGE_SIZE);
    }

    componentDidUpdate(prevProps) {
        const {banners, message, done, error} = this.props;

        prevProps.banners !== banners && this.setState({data: banners});
        prevProps.message !== message && message && prevProps.done !== done && this.renderNotification('success');
        prevProps.error !== error && error && this.renderNotification('danger');
    }

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message: type === 'success' ? t(`notification:${type}->message`) : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    }

    handleSearchWord(e) {
        this.setState({ filterText: e.target.value })
        clearTimeout(this.state.timeout);
        this.state.timeout = setTimeout(() => {
            this.setState({ newItems: this.props.fetchBanners(CURRENT_PAGE, PAGE_SIZE, this.state.filterText) });
        }, SEARCH_TIMEOUT);
    }

    renderLoading = () => {
        return <LoadingElement isLoading={true} />;
    }    

    handlePageChange = page => {
        const {pageSize} = this.state;
        this.setState({currentPage: page});
        this.props.fetchBanners(page, pageSize);
    };

    handlePerRowsChange = (pageSize, page) => {
        this.setState({pageSize: pageSize, currentPage: page});
        this.props.fetchBanners(page, pageSize);
    };

    handleToggle = (row) => {
        this.props.toggleBanner(row);
    };

    handleModalDelete(id) {
        const {pageSize, currentPage} = this.state;
        this.props.deleteBanner(id, 'list', pageSize, currentPage);
    }

    renderTableHeader() {
        const {t} = this.props;

        return (
            <div className="card__title">
                <h5 className="bold-text">{t('banners:bannerList->tableTitle')}</h5>
                <ButtonToolbar className="products-list__btn-toolbar-top">
                    <div className="form">
                        <div className="form__form-group products-list__search">
                            <label htmlFor="search" className="sr-only">
                                {t('banners:bannerList->searchTextPlaceholder')}
                            </label>
                            <input
                                type="text"
                                id="search"
                                value={this.state.filterText}
                                onChange={this.handleSearchWord.bind(this)}
                                placeholder={t('banners:bannerList->searchTextPlaceholder')}
                                autoComplete="off"/>
                            <MagnifyIcon/>
                        </div>
                    </div>
                    <Link
                        className="btn btn-primary products-list__btn-add"
                        to="/banners/create">{t('banners:bannerList->createNew')}
                    </Link>
                </ButtonToolbar>
            </div>
        );
    };

    renderTableColumns() {
        const {t} = this.props;

        return [
            {
                name: t('banners:bannerList->tableColumnName'),
                selector: 'title',
                sortable: true,
                wrap: true,
            },
            {
                name: t('banners:bannerList->tableColumnCreatedDate'),
                selector: 'createdDate',
                sortable: true,
                wrap: true,
            },
            {
                name: t('banners:bannerList->tableColumnCreatedBy'),
                selector: 'createdBy',
                sortable: true,
                wrap: true,
            },
            {
                name: t('banners:bannerList->tableColumnModifiedDate'),
                selector: 'modifiedDate',
                sortable: true,
                wrap: true,
            },
            {
                name: t('banners:bannerList->tableColumnModifiedBy'),
                selector: 'modifiedBy',
                sortable: true,
                wrap: true,
            },
            {
                name: t('banners:bannerList->tableColumnActive'),
                button: true,
                maxWidth: '50px',
                cell: row => (
                    <label className="toggle-btn customizer__toggle" htmlFor={`toggle_${row.id}`}>
                        <input
                            className="toggle-btn__input"
                            type="checkbox"
                            name={`toggle_${row.id}`}
                            id={`toggle_${row.id}`}
                            checked={row.isEnabled}
                            value={row}
                            onChange={() => this.handleToggle(row)}
                        />
                        <span className="toggle-btn__input-label"/>
                    </label>
                )
            },
            {
                button: true,
                cell: row => (
                    <ButtonToolbar>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                            <Link to={`/banners/edit/${row.id}`}><Button outline><span
                                className="lnr lnr-pencil"/></Button></Link>
                            <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                outline
                                firstBtnText={t('modal:modal->buttonNo')}
                                secondBtnText={t('modal:modal->buttonYes')}
                                title={t('banners:bannerList->modalDeleteTitle')}
                                btn={<span className="lnr lnr-trash"/>}
                                message={<p>{t('banners:bannerList->modalDeleteMessage')}</p>}
                                handleOnClick={() => this.handleModalDelete(row.id)}
                            />
                        </ButtonGroup>
                    </ButtonToolbar>
                ),
            }
        ]
    }

    renderTable() {
        const {rowsPerPage, pageSize} = this.state;
        const {t, done, totalItems, theme} = this.props;
        let filteredItems = [];

        if (done) {
            filteredItems = this.state.data
        }

        const paginationOptions = {
            rowsPerPageText: t('common:table->rowsPerPageText') ,
            rangeSeparatorText: t('common:table->rangeSeparatorText')
        };

        return (
            <>
                {this.renderTableHeader()}
                <DataTable
                    keyfield={filteredItems.id}
                    customStyles={theme === 'theme-dark' ? DATATABLE_DARK_MODE_STYLES : {}}
                    columns={this.renderTableColumns()}
                    data={filteredItems}
                    defaultSortField="title"
                    noDataComponent={t('common:table->noDataComponent')}
                    progressPending={!done}
                    pagination
                    paginationServer
                    progressComponent={this.renderLoading()}
                    paginationTotalRows={totalItems}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={paginationOptions}
                    paginationRowsPerPageOptions={rowsPerPage}
                    paginationPerPage={pageSize}/>
            </>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <Container>
                <Row>
                    <Col>
                        <h3 className="page-title mb-4">{t('banners:bannerList->pageTitle')}</h3>
                        <Card>
                            <CardBody>
                                {this.renderTable()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        banners: Object.values(state.banners.data.banners),
        totalItems: state.banners.data.totalItems,
        done: state.banners.done.status,
        message: state.banners.done.message,
        error: state.banners.error,
        theme: state.theme.className
    };
};

export default compose(withTranslation(['content', 'common', 'modal', 'notification']), connect(mapStateToProps, {
    fetchBanners,
    toggleBanner,
    deleteBanner
}))(BannerList);