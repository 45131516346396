import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import MaskedInput from 'react-text-mask';

class InputElement extends React.Component {
    static propTypes = {
        input: PropTypes.shape({
            onChange: PropTypes.func,
            name: PropTypes.string,
        }).isRequired,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
            submitFailed: PropTypes.bool
        }),
        label: PropTypes.string,
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        placeholder: PropTypes.string,
        autoComplete: PropTypes.string,
        mask: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.any),
            PropTypes.func
        ]),
        guide: PropTypes.bool,
        numeric: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        isLatitudeLongitude: PropTypes.bool,

    }
    
    handleOnChange(e, onChange) {
        const regex = RegExp(/^[+-]?\d*[.|,]?\d*$/);
        let value = e.target.value;
        if (value === '' || regex.test(value)) {
            onChange(value);
        }
    }

    handleOnChangeLatitudeLongitude(e, onChange) {
        const regex = RegExp(/^[+-]?\d{0,3}[.]\d{0,8}()*$|^[+-]?\d{0,3}$/);
        //const regex = RegExp(/^[+-]\d{2}\.\d{6}/);
        let value = e.target.value;
        if (value === '' || regex.test(value)) {
            onChange(value);
        }
    }

    renderErrorMessage = (error) => {
        const {t} = this.props;
        if (error.includes('maxLength')) {
            return t('validate->maxLength', {max: error.split('-')[1]});
        }
        if (error.includes('minLength')) {
            return t('validate->minLength', {min: error.split('-')[1]});
        }
        return t(`validate->${error}`);
    };

    render() {
        const { input, meta: { touched, error, submitFailed }, label, id, type, placeholder, autoComplete, mask, guide, numeric, disabled, className, isLatitudeLongitude} = this.props;
        return (
            <div className={`form__form-group ${className}`}>
                <label className="form__form-group-label" htmlFor={id}>{label}</label>
                <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                        {
                            mask ?
                                <MaskedInput
                                    {...input}
                                    id={id}
                                    placeholder={placeholder}
                                    type={type}
                                    mask={mask}
                                    guide={guide}
                                    disabled={disabled}
                                />
                                :
                                numeric ?
                                    <input
                                        {...input}
                                        type={type}
                                        id={id}
                                        disabled={disabled}
                                        placeholder={placeholder}
                                        autoComplete={autoComplete}
                                        onChange={e => this.handleOnChange(e, input.onChange)}
                                    />
                                    :
                                    isLatitudeLongitude ?
                                        <input
                                            {...input}
                                            type={type}
                                            id={id}
                                            disabled={disabled}
                                            placeholder={placeholder}
                                            autoComplete={autoComplete}
                                            onChange={e => this.handleOnChangeLatitudeLongitude(e, input.onChange)}
                                        />
                                        :
                                        <input
                                            {...input}
                                            type={type}
                                            id={id}
                                            disabled={disabled}
                                            placeholder={placeholder}
                                            autoComplete={autoComplete}
                                        />
                        }
                        {
                            touched && error && submitFailed &&
                            <span className="form__form-group-error">
                                {
                                    this.renderErrorMessage(error)
                                }
                            </span>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('validation')(InputElement); 